import React, { useEffect, useState } from 'react'
import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import './aboutUs.scss';
import aboutUsCompanyImage from "../../../assets/images/aboutUs/about_us_banner.png"
import insideCompanyImage2 from "../../../assets/images/aboutUs/Reth-nesh-2.jpg"
import insideCompanyImage1 from "../../../assets/images/aboutUs/Rreth-nesh-1.JPG"
// import ourPartners from "../../DB/partneret"

import Translations from '../../../components/translations/translations';
import TranslationModal from '../../translatePagesModal/translationModal';


const AboutUs = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    const [isOpen, setOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);
    const handleEditLabelModal = (key) => {
        setOpen(true);
        setDataToModal(key)
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };

    return (
        <>
            {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}
            <div className="about_us_layout">
                <img className="img-fluid about_us_company_image" src={aboutUsCompanyImage}/>
                <div className="container">
                    <div className="about_us_content">
                        <p className='about_us_page_title mt-5' role='button' onClick={() => { handleEditLabelModal("AboutUs") }}>{Translations(props?.langData, props?.selected_lang, 'AboutUs')}</p>
                        <p className='company_description mb-5' role='button' onClick={() => { handleEditLabelModal("paragrafiPareTekRrethNeshe") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'paragrafiPareTekRrethNeshe')}` }}></p>
                        <img className='img-fluid about_us_inside_company_image' src={insideCompanyImage1} alt="Inside company image" />
                        <p className='company_description mt-5 mb-5' role='button' onClick={() => { handleEditLabelModal("paragrafiDyteTekRrethNeshe") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'paragrafiDyteTekRrethNeshe')}` }}>

                        </p>
                        <img className='img-fluid about_us_inside_company_image' src={insideCompanyImage2} alt="Inside company image" />
                    </div>
                    <div className="mt-5 mb-5 partneretTane">
                    </div>
                </div>

            </div>
        </>

    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);