import { useState } from 'react';
import './forgotPass.scss';
import axios from '../../../axios';
import companyImage from '../../../data/images/page-images/about_us_banner.webp'
import { toast } from 'react-toastify';


import { connect } from 'react-redux'
import { setLogIn, setAuth } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = (props) => {
    // console.log('logjin props', props)
    const [email, setEmail] = useState('');
    const navigate = useNavigate()
    async function handleLogin(e) {
        e.preventDefault();
        axios.post('/api/forgot-password/generate-link', { email: email })
            .then(async (res) => {
                navigate('/changePassMsg')
                toast.success('Email for password Change sent!');
            })
            .catch((err) => {
                console.error(err);
                toast.error('Error occurred while sending change password email.');
            });
    }

    return (
        <div className="container-fluid h-100">
            <div className="row h-100 min-vh-100">
                <div className="col-md-12 d-md-block p-0 background-color">
                    <img className="w-100 h-100 object-fit-cover" src={companyImage} alt="Company image" />
                </div>
                <div className="col-md-12 d-flex flex-column justify-content-center min-vh-100 login-wrapper">
                    <form onSubmit={handleLogin} className="ms-auto me-md-5 col-3 me-auto rounded-lg login-form p-md-5 p-2 rounded">
                        <h2 className="text-4xl text-white font-weight-bold text-center col">Request Password Change</h2>
                        <div className="form-group text-white py-2">
                            <label htmlFor="username" className='col'>Email</label>
                            <input className="form-control mt-2 p-2" required onChange={(e) => { setEmail(e.target.value) }} type="email" id="username" />
                        </div>
                        {/* <div className="form-group text-white py-2">
                            <label htmlFor="password" className='col'>Confirm new Password</label>
                            <input className="form-control p-2 mt-2" onChange={(e) => { setConfrimNewPass(e.target.value) }} type="password" id="password" autoComplete="current-password" />
                        </div> */}
                        {/* <a href='#' className='col'>Forgot Password!</a> */}
                        <button type="submit" className="col-12 btn btn-primary btn-block mt-3">Request Email</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

// export default Login;

const mapStateToProps = (state) => {
    return {
        logged_in: state.logged_in,
        counter: state.counter
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setLogIn: (data) => dispatch(setLogIn(data)),
        setAuth: (data) => dispatch(setAuth(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);