import axios from '../../../axios'
import React, { useState, useEffect } from 'react'
import "./labelsModal.scss"
import { toast } from 'react-toastify';

const Labels = (props) => {
  const [labelKey, setLabelKey] = useState("")
  const [labelAl, setLabelAl] = useState("")
  const [labelEn, setLabelEn] = useState("")
  const [labelDe, setLabelDe] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  // console.log('labels props, props', props)


  useEffect(() => {
    // document.getElementsByClassName("content-container")[0].style.position = "absolute";
  }, []);

  function handleModalClose(event) {
    if (event.target === event.currentTarget) {
      props?.handleModalClose();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    let body = {
      key: labelKey,
      content_al: labelAl,
      content_en: labelEn,
      content_de: labelDe
    }
    axios.post("/api/admin/labels", body)
      .then((res) => {
        toast.success('Labela u krijua me sukses')
        props?.handleGetData()
        props?.handleModalClose()
        // console.log('res', res)
      })
      .catch(err => {
        toast.error('Pat nje error gjat krijimit te labeles!')
        console.log('error', err)
      })
      .finally((finalRes) => {
        // console.log("finalRes", finalRes)
        setIsSubmitting(false);

      })
  }

  function handleEditSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    // console.log("editi", e)
    let body = {
      id: props?.dataToModal?.id,
      key: labelKey,
      content_al: labelAl,
      content_en: labelEn,
      content_de: labelDe
    }
    axios.put("/api/admin/labels", body)
      .then((res) => {
        toast.success('Labela u editua me sukses')
        props?.handleGetData()
        props?.handleModalClose()
      })
      .catch(err => {
        toast.error('Pat nje error gjat editimit te labeles!')
        console.log('error', err)
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  }
  function handleDeleteLabel(id) {
    axios.delete(`/api/admin/labels/${id}`)
      .then((res) => {
        // console.log('res.data', res)
        toast.success("Labela i fshi me sukses!")
        props?.handleGetData()
      })
      .catch((err) =>
        console.log("error getting labels", err))
  }

  function autoAdjustTextareaHeight(e) {
    const textareas = document.querySelectorAll('.dynamic-textarea');

    let maxHeight = 0;

    textareas.forEach((textarea) => {
      textarea.style.height = 'auto';
      maxHeight = Math.max(maxHeight, textarea.scrollHeight);
    });

    textareas.forEach((textarea) => {
      textarea.style.height = `${maxHeight}px`;
    });
  }
  function setInitialTextareaHeight() {
    const textareas = document.querySelectorAll('.auto-adjust-textarea');

    textareas.forEach((textarea) => {
      textarea.style.height = 'auto'; // Reset the height to auto to calculate the new height
      textarea.style.height = textarea.scrollHeight + 'px'; // Set the height to match the content's scrollHeight
    });
  }

  useEffect(() => { setInitialTextareaHeight() }, [])
  return (
    <>{
      props?.type == "add" ?
        <dialog id="myModal" onClick={(e) => handleModalClose(e)} class="modal">
          <div class="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header mb-2">
              <p>
                Shto një label të re
              </p>
              <a className='closeButton' role='button' onClick={() => props?.handleModalClose()}>
                <i class="fa fa-window-close" aria-hidden="true">

                </i>
              </a>
            </div>
            <form onSubmit={(e) => { handleSubmit(e); setIsSubmitting(true) }}>

              <div class="mb-3">
                <span className="text-left">Key: </span>
                <textarea type="text" onChange={(e) => setLabelKey(e.target.value)} value={labelKey} class="form-control" placeholder="Key" />
              </div>
              <div className="row mb-3">
                <div class="col-4">
                  <span className="text-left"  >Labela Shqip: </span>
                  <textarea type="text" onInput={(e) => autoAdjustTextareaHeight(e)} onChange={(e) => setLabelAl(e.target.value)} value={labelAl} class="form-control dynamic-textarea auto-adjust-textarea" placeholder="Shqip" />
                </div>
                <div class="col-4">
                  <span className="text-left">Labela Anglisht: </span>
                  <textarea type="text" onInput={(e) => autoAdjustTextareaHeight(e)} onChange={(e) => setLabelEn(e.target.value)} value={labelEn} class="form-control dynamic-textarea auto-adjust-textarea" placeholder="Anglisht" />
                </div>
                <div class="col-4">
                  <span className="text-left">Labela Gjermanisht: </span>
                  <textarea type="text" onInput={(e) => autoAdjustTextareaHeight(e)} onChange={(e) => setLabelDe(e.target.value)} value={labelDe} class="form-control dynamic-textarea auto-adjust-textarea" placeholder="Gjermanisht" />
                </div>
              </div>
              <div class="button-container">
                <button type="submit" class="btn btn-primary" disabled={isSubmitting ? true : null}>Submit</button>
              </div>
            </form>
          </div>
        </dialog>
        :
        props?.type == "edit" ?
          <dialog id="myModal" onClick={(e) => handleModalClose(e)} class="modal">
            <div class="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-header">
                <p>
                  Edit modal
                </p>
                <a className='closeButton' role='button' onClick={() => props?.handleModalClose()}><i class="fa fa-window-close" aria-hidden="true"></i>
                </a>
              </div>
              <form onSubmit={(e) => { handleEditSubmit(e); setIsSubmitting(true) }}>
                <div class="mb-3">
                  <span className='text-left'>Label Key: </span>
                  <textarea type="text" disabled onChange={(e) => setLabelKey(e.target.value)} value={props?.dataToModal?.key} class="form-control" placeholder="Key" />
                </div>
                <div className="row mb-3">
                  <div class="col-4">
                    <span className='text-left'>Labela Shqip: </span>
                    <textarea type="text" onInput={(e) => autoAdjustTextareaHeight(e)} onChange={(e) => setLabelAl(e.target.value)} value={labelAl || (props?.dataToModal?.content_al || '')} class="form-control dynamic-textarea auto-adjust-textarea" placeholder="Shqip" />
                  </div>
                  <div class="col-4">
                    <span className='text-left'>Labela Anglisht: </span>

                    <textarea type="text" onInput={(e) => autoAdjustTextareaHeight(e)} onChange={(e) => setLabelEn(e.target.value)} value={labelEn || (props?.dataToModal?.content_en || '')} class="form-control dynamic-textarea auto-adjust-textarea" placeholder="Anglisht" />
                  </div>
                  <div class="col-4">
                    <span className='text-left'>Labela Gjermanisht: </span>

                    <textarea type="text" onInput={(e) => autoAdjustTextareaHeight(e)} onChange={(e) => setLabelDe(e.target.value)} value={labelDe || (props?.dataToModal?.content_de || '')} class="form-control dynamic-textarea auto-adjust-textarea" placeholder="Gjermanisht" />
                  </div>
                </div>
                <div class="button-container">
                  <button type="submit" class="btn btn-primary" disabled={isSubmitting ? true : null}>Submit</button>
                </div>
              </form>
            </div>
          </dialog>
          : props?.type == "delete" ?
            <dialog id="myModal" onClick={(e) => handleModalClose(e)} className="modal">
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                  <p>Delete Confirmation</p>
                  <a className="closeButton" role="button" onClick={() => props?.handleModalClose()}>
                    <i className="fa fa-window-close" aria-hidden="true"></i>
                  </a>
                </div>
                <form onSubmit={(e) => { handleDeleteLabel(props?.dataToModal?.id); setIsSubmitting(true) }}>
                  <div className="mb-3">
                    <span className="text-left">Label Key: </span>
                    <textarea
                      type="text"
                      disabled
                      value={props?.dataToModal?.key}
                      className="form-control"
                      placeholder="Key"
                    />
                  </div>
                  <div className="row mb-3">
                    <div className="col-4">
                      <span className="text-left">Labela Shqip: </span>
                      <textarea
                        type="text"
                        disabled
                        value={labelAl || (props?.dataToModal?.content_al || '')}
                        className="form-control dynamic-textarea auto-adjust-textarea "
                        placeholder="Shqip"
                      />
                    </div>
                    <div className="col-4">
                      <span className="text-left">Labela Anglisht: </span>
                      <textarea
                        type="text"
                        disabled
                        value={labelEn || (props?.dataToModal?.content_en || '')}
                        className="form-control dynamic-textarea auto-adjust-textarea"
                        placeholder="Anglisht"
                      />
                    </div>
                    <div className="col-4">
                      <span className="text-left">Labela Gjermanisht: </span>
                      <textarea
                        type="text"
                        disabled
                        value={labelDe || (props?.dataToModal?.content_de || '')}
                        className="form-control dynamic-textarea auto-adjust-textarea"
                        placeholder="Gjermanisht"
                      />
                    </div>
                  </div>
                  <div class="delete-button-container">
                    <p className='delete-confirmation-text'>Are you sure you want to delete this label?</p>
                    <button type="submit" class="btn btn-danger" disabled={isSubmitting ? true : null}>Delete</button>
                  </div>
                </form>
              </div>
            </dialog>


            :
            null
    }
    </>
  )
}

export default Labels
