import React, { useEffect, useState } from 'react'
import "./forPublic.scss"
import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Translations from '../../../components/translations/translations';
import alumin_imgKatallog from "../../../assets/images/forPublic/Katalogu_alumin.png"
import mirenjohjetImage from "../../../assets/images/forPublic/Mirenjohje_M-Technologie.png"
import certifikatatImage from "../../../assets/images/forPublic/Certifikata_M-Technologie.png"
import productsBannes from "../../../assets/images/forPublic/Benneri_Cka-ofrojme.jpg"
import StandardiXhamit from '../standardiXhamit/standardiXhamit';
import TranslationModal from '../../translatePagesModal/translationModal';


const ForPublic = (props) => {
    const [isOpen, setOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);

    const handleEditLabelModal = (key) => {
        setOpen(true);
        setDataToModal(key)
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    var firstSlickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 800,
        cssEase: "linear"
    };
    return (
        <>
            {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}
            <div className="our_prducts_layout">
                <div className='opening_parent_slider'>
                    <div className="banner_layout">
                        <img src={productsBannes} className="single_product_image_banner img-fluid" alt="..." />
                        <div className="container banner_description">
                            <p className='col' role='button' onClick={() => { handleEditLabelModal("forPublic") }}>{Translations(props?.langData, props?.selected_lang, 'forPublic')}</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-5 mb-5">
                        <div className=" col-md-8 col-12 single_product_layout my-auto">
                            <h1 className='single_product_title' role='button' onClick={() => { handleEditLabelModal("KatalogjetLabel") }}>{Translations(props?.langData, props?.selected_lang, 'KatalogjetLabel')}</h1>
                            <p className='single_product_description_main_page' role='button' onClick={() => { handleEditLabelModal("Katallogjet-Teksti-tek-perpublikun-tona-Label") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Katallogjet-Teksti-tek-perpublikun-tona-Label')}` }}>
                            </p>
                            <Link
                                // to="/katallogjet" 
                                className='single_product_button' role='button' onClick={() => { handleEditLabelModal("Më-shumë-LabellProducts") }}>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                        </div>
                        <img className="col-sm-3 col-md-4 col-12 img-fluid" src={alumin_imgKatallog} style={{ width: "415px" }} />
                    </div>
                    <div className="row mt-4 mb-2">
                        <img className="col-sm-3 col-md-4 col-12 " src={mirenjohjetImage} style={{ width: "415px" }} />
                        <div className="col-md-8 col-12 single_product_layout my-auto">
                            <h1 className='single_product_title' role='button' onClick={() => { handleEditLabelModal("MirënjohjetLabel") }}>{Translations(props?.langData, props?.selected_lang, 'MirënjohjetLabel')}</h1>
                            <p className='single_product_description_main_page' role='button' onClick={() => { handleEditLabelModal("Mirenjohjet-Teksti-tek-perpublikun-tona-Label") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Mirenjohjet-Teksti-tek-perpublikun-tona-Label')}` }}>

                            </p>
                            <Link
                                // to="/mirenjohjet"
                                className='single_product_button' role='button' onClick={() => { handleEditLabelModal("Më-shumë-LabellProducts") }}>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                        </div>
                    </div>
                    <div className="row mt-4 mb-5">
                        <div className="col-md-8 col-12 single_product_layout my-auto">
                            <h1 className='single_product_title' role='button' onClick={() => { handleEditLabelModal("ÇertifikatatLabel") }}>{Translations(props?.langData, props?.selected_lang, 'ÇertifikatatLabel')}</h1>
                            <p className='single_product_description_main_page' role='button' onClick={() => { handleEditLabelModal("Certifikatat-Teksti-tek-perpublikun-tona-Label") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Certifikatat-Teksti-tek-perpublikun-tona-Label')}` }}>
                            </p>
                            <Link
                                // to="/certifikatat"
                                className='single_product_button' role='button' onClick={() => { handleEditLabelModal("Më-shumë-LabellProducts") }}>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                        </div>
                        <img className="col-sm-3 col-md-4 col-12 img-fluid" src={certifikatatImage} style={{ width: "415px" }} />
                    </div>
                </div>
                <StandardiXhamit />
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: () => dispatch(setLangData()),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForPublic);
// export default ForPublic