import React, { useEffect, useState } from 'react'
import AddLabelsModal from './modals/addLabels';
import axios from '../../axios';
import editIcon from '../../data/icons/edit.svg'
import deleteIcon from '../../data/icons/delete.svg'
import { toast } from 'react-toastify';

const Labels = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState('')
    const [allLabels, setAllLabels] = useState()
    const [dataToModal, setDataToModal] = useState()

    function handleModalAdd() {
        setIsOpen(true)
        setModalType('add')
    }
    function handleModalEdit(e) {
        setIsOpen(true)
        setDataToModal(e)
        setModalType('edit')
    }
    function handleModalDelete(e) {
        setIsOpen(true)
        setDataToModal(e)
        setModalType('delete')
    }
    function handleModalClose() {
        setIsOpen(false)
    }
    function handleGetAllLabels() {
        axios.get("/api/admin/labels/all")
            .then((res) => {
                // console.log('res.data', res)
                setAllLabels(res.data)
            })
            .catch((err) =>
                console.log("error getting labels", err))
    }


    useEffect(() => {
        handleGetAllLabels()
    }, [])
    return (
        <>
            {isOpen === true && <AddLabelsModal dataToModal={dataToModal} type={modalType} isOpen={isOpen} handleGetData={handleGetAllLabels} handleModalClose={handleModalClose} />}
            <div>
                <h2 className='text-center mb-3' >Ketu behen te gjitha perkthimet</h2>
                <table className=" col-12 table table table-hover">
                    <thead>
                        <tr>
                            <th className="col-2">Key</th>
                            <th className="col-2">Shqip</th>
                            <th className="col-2">English</th>
                            <th className="col-2">German</th>
                            <th className="col-2">Edit</th>
                            <th className="col-2">Delete</th>
                        </tr>
                    </thead>
                    <tbody>

                        {allLabels?.map((el, index) =>
                            <tr key={index}>
                                <th scope="row">{el?.key}</th>
                                <td className='col-2'>{el?.content_al}</td>
                                <td className='col-2'>{el?.content_en}</td>
                                <td className='col-2'>{el?.content_de}</td>
                                <td className='col-2' onClick={() => handleModalEdit(el)} ><img src={editIcon} alt="" /></td>
                                <td className='col-2' onClick={() => handleModalDelete(el)}><img src={deleteIcon} alt="" /></td>

                                {/* <td className='col-2'><i className='fas fa-trash' /></td> */}
                            </tr>

                        )}
                    </tbody>
                </table>
                <div className="addButton" onClick={() => handleModalAdd()}>
                    <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5 " style={{ color: "#0d6efd" }} ></i>
                </div>
            </div>
        </>
    )
}

export default Labels