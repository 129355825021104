import React, { useEffect, useState } from 'react'
import ourPartners from "../../DB/partneret"
import AddPartnerModal from './modals/addPartnerModal'
import axios from '../../axios'
import { toast } from 'react-toastify'
import "./ourPartners.scss"

const OurPartners = () => {
    const [partners, setPartners] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [dataToModal, setDataToModal] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [selectedImage, setSelectedImage] = useState(null);
    // console.log('selectedImage', selectedImage)
    // console.log('imageUrl', imageUrl)

    const deleteIcons = document.querySelectorAll('.delete_icon');

    deleteIcons.forEach((icon) => {
        icon.addEventListener('mouseenter', () => {
            icon.closest('.partner_wrapper').classList.add('hovered');
        });

        icon.addEventListener('mouseleave', () => {
            icon.closest('.partner_wrapper').classList.remove('hovered');
        });
    });


    function handleModalAdd() {
        setIsOpen(true)
        setModalType('add')
    }

    function handleDeleteModal(e) {
        // console.log('erdah')
        setIsOpen(true)
        setModalType('delete')
        setDataToModal(e)
    }

    function handleModalClose() {
        setIsOpen(false)
    }
    function handleGetPartners() {
        axios.get("/api/admin/our_partners/all")
            .then(res => {
                setPartners(res.data)
            })
            .catch(err => {
                toast.error(err)
                console.log('error getting partners ', err)
            })
    }
    useEffect(() => {
        handleGetPartners()
    }, [])


    return (
        <>
            {isOpen && <AddPartnerModal handleGetPartners={() => handleGetPartners()} handleModalClose={handleModalClose} isOpen={isOpen} dataToModal={dataToModal} modalType={modalType} />}
            <div className="container mt-5  partneretTane">
                <h2 className='text-center mb-5' >Ketu listohen te gjitha partneret e tanë</h2>
                <div className="partners_container">

                    {partners.map((el) => {
                        return (
                            <div className='partner_wrapper'>
                                <img src={`${process.env.REACT_APP_API}/${el?.img_url}`} alt={el.img_alt} />
                                <a onClick={(e) => { handleDeleteModal(el) }}> <i className='delete_icon fa fa-trash'></i></a>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="addButton" onClick={handleModalAdd}>
                <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5 " style={{ color: "#0d6efd" }} ></i>
            </div>
        </>
    )
}

export default OurPartners