import React, { useEffect, useState } from 'react'
import axios from '../../axios'
import { toast } from 'react-toastify'
import "./news.scss"
import AddNews from './modals/addNewsModal'
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';

const News = () => {
    const [news, setNews] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [dataToModal, setDataToModal] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [selectedImage, setSelectedImage] = useState(null);
    // console.log('selectedImage', selectedImage)
    // console.log('imageUrl', imageUrl)

    const deleteIcons = document.querySelectorAll('.delete_icon');

    deleteIcons.forEach((icon) => {
        icon.addEventListener('mouseenter', () => {
            icon.closest('.partner_wrapper').classList.add('hovered');
        });

        icon.addEventListener('mouseleave', () => {
            icon.closest('.partner_wrapper').classList.remove('hovered');
        });
    });


    function handleModalAdd() {
        setIsOpen(true)
        setModalType('add')
    }

    function handleDeleteModal(e) {
        // console.log('erdah')
        setIsOpen(true)
        setModalType('delete')
        setDataToModal(e)
    }
    function handleEditModal(e) {
        // console.log('erdah')
        setIsOpen(true)
        setModalType('edit')
        setDataToModal(e)
    }

    function handleModalClose() {
        setIsOpen(false)
        setDataToModal(null)
    }
    function handleGetPartners() {
        axios.get("/api/admin/our_staff/all")
            .then(res => {
                setNews(res.data)
            })
            .catch(err => {
                toast.error(err)
                console.log('error getting news ', err)
            })
    }
    function handleGetPartners() {
        axios.get("/api/admin/news/all")
            .then(res => {
                setNews(res.data)
            })
            .catch(err => {
                toast.error(err)
                console.log('error getting news ', err)
            })
    }
    useEffect(() => {
        handleGetPartners()
    }, [])

    // const AppApi = `https://api.m-technologie.com/`
    const AppApi = process.env.REACT_APP_API
    return (
        <>
            {isOpen && <AddNews handleGetPartners={() => handleGetPartners()} handleModalClose={handleModalClose} isOpen={isOpen} dataToModal={dataToModal} modalType={modalType} />}
            <div className="container   partneretTane">
                <div className="about_us_content">
                    <p className='about_us_page_title mt-5' dangerouslySetInnerHTML={{ __html: `News` }} />
                    <hr className='vizaNdarese ' />

                    <div className="row py-5">
                        {news?.map((el, index) => {
                            return (
                                <div key={index} className="col-md-3 mb-3">
                                    <div className="border border-dark rounded" style={{ minHeight: '500px', maxHeight: '500px' }}>
                                        <img className="img-fluid rounded-top " style={{ height: "225px", objectFit: "cover" }} src={`${AppApi}/${el?.img_url}`} alt="" />
                                        <div className="container">

                                            <p className="news-name" dangerouslySetInnerHTML={{ __html: `${el?.title?.slice(0, 13)} ${el?.title?.length> 12 ? "..." : ""}` }} />
                                            <p className="news-location my-3">
                                                <i className="fas fa-map-marker-alt" aria-hidden="true" />{' '}
                                                {el?.location}
                                                {/* {new Date(el?.date).getDate()}/{new Date(el?.date).getMonth()}/{new Date(el?.date).getFullYear()} */}
                                            </p>
                                            <p className="news_description col-12" style={{ minHeight: '100px', maxHeight: '100px' }} dangerouslySetInnerHTML={{ __html: `${el?.news_text.length > 110 ? el?.news_text.slice(0, 110) + '...' : el?.news_text}` }} />
                                            <div className="container d-flex justify-content-between p-0 ">

                                                <Button onClick={() => handleEditModal(el)} className="mb-3" variant="contained" color="primary" startIcon={<EditIcon />}>
                                                    Edit
                                                </Button>
                                                <Button onClick={() => handleDeleteModal(el)} className="mb-3" variant="contained" color="secondary" startIcon={<DeleteIcon />}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}

                    </div>

                </div>

            </div>
            <div className="addButton" onClick={handleModalAdd}>
                <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5 " style={{ color: "#0d6efd" }} ></i>
            </div>
        </>
    )
}

export default News