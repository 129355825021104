import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Card,
  CardContent,
  CardMedia,
  Box,
  Typography,
  Checkbox,
  FormGroup,
} from '@mui/material';
import axios from '../../../axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

function AddProjectsModal(props) {
  // console.log('AddProjectsModal props', props)
  const [formData, setFormData] = useState({
    bg_img: null,
    Title: '',
    is_homepage_slider: false,
    business_type_id: '',
    status_id: '',
    obiject_name_al: '',
    obiject_name_en: '',
    obiject_name_de: '',
    location_al: '',
    location_en: '',
    location_de: '',
    kontenti_imazhi_kontentit: '',
    kontenti: {
      detajet_e_projektit_al: '',
      detajet_e_projektit_en: '',
      detajet_e_projektit_de: '',
      materialet: [],
    },
    galleria: []
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData };
    const keys = name.split('.');

    let current = updatedData;

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      current[key] = current[key] || {};
      current = current[key];
    }


    current[keys[keys.length - 1]] = value;
    setFormData(updatedData);
  };

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    const updatedMaterialet = [...formData.kontenti.materialet];
    if (updatedMaterialet.includes(value)) {
      updatedMaterialet.splice(updatedMaterialet.indexOf(value), 1);
    } else {
      updatedMaterialet.push(value);
    }
    setFormData({
      ...formData,
      kontenti: {
        ...formData.kontenti,
        materialet: updatedMaterialet,
      },
    });
    // console.log('formdata click', formData)
  };


  const handleImageUpload = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];

    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleGalleryImageUpload = (e) => {
    const files = e.target.files;
    const updatedGalleria = [...formData.galleria];

    for (let i = 0; i < files.length; i++) {
      updatedGalleria.push(files[i]);
    }

    setFormData({
      ...formData,
      galleria: updatedGalleria,
    });
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImagePreview = (file) => {
    const openImageModal = (file) => {
      setSelectedImage(file);
    };
    const closeImageModal = () => {
      setSelectedImage(null);
    };
    if (file) {
      return (
        <div onClick={() => openImageModal(file)}>
          <Card>
            <CardMedia
              component="img"
              alt="Uploaded Image"
              height="140"
              image={URL.createObjectURL(file)}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {file.name}
              </Typography>
            </CardContent>
          </Card>
        </div>
      );
    }
    if (selectedImage) {
      return (
        <Dialog
          open={selectedImage !== null}
          onClose={closeImageModal}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Uploaded Image"
              style={{ width: '100%', maxHeight: '70vh', objectFit: 'contain' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeImageModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  };
  const handleSubmit = () => {
    const formDataToSend = new FormData();

    for (const key in formData) {
      if (key === 'kontenti') {
        formDataToSend.append(key, JSON.stringify(formData[key]));
      } else if (key === 'galleria') {
        for (const image of formData[key]) {
          formDataToSend.append('galleria', image);
        }
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    axios
      .post('api/admin/projects/create', formDataToSend)
      .then((res) => {
        toast.success(`Projekti u krijua me sukses!`)
        // console.log('Response:', res);
        props?.getAllProjects();
        // props?.handleModalClose();
      })
      .catch((err) => {
        toast.error(`Error: ${err}`);
        console.error('Error:', err);
      })
      .finally(() => {
        // console.log('Form Data:', formData);
      });
  };
  const [allProjectTypes, setAllProjectTypes] = useState()
  const [allProjectStatuses, setAllProjectStatuses] = useState()
  const [allProjectMaterials, setAllProjectMaterials] = useState()

  function getProjectTypes() {
    axios.get('/api/admin/project_types/all')
      .then(res =>
        setAllProjectTypes(res.data)
      )
      .catch(err => {
        toast.error(`Error getting project types`)
        console.log(err)
      })
  }
  function getProjectStatuses() {
    axios.get('/api/admin/project_status/all')
      .then(res =>
        setAllProjectStatuses(res.data)
      )
      .catch(err => {
        toast.error(`Error getting project types`)
        console.log(err)
      })
  }
  function getProjectMaterials() {
    axios.get('/api/admin/project_materials/all')
      .then(res =>
        setAllProjectMaterials(res.data)
      )
      .catch(err => {
        toast.error(`Error getting project types`)
        console.log(err)
      })
  }
  useEffect(() => {
    getProjectTypes()
    getProjectStatuses()
    getProjectMaterials()
  }, [])
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={props?.handleModalOpen}>
        Open Modal
      </Button> */}
      <Dialog open={props?.isOpen} onClose={props?.handleModalClose} maxWidth="lg">
        <DialogTitle>Insert Data</DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <input
            type="file"
            accept="image/*"
            id="bg_img"
            name="bg_img"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <label htmlFor="bg_img">
            <Button component="span" variant="outlined" color="primary">
              Upload Project Background Image
            </Button>
          </label>
          {handleImagePreview(formData.bg_img)}

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{
              position: 'absolute',
              background: 'white',
              zIndex: 1,
              border: formData.is_homepage_slider ? '1px solid #000' : 'none',
            }} htmlFor="is_homepage_slider">Is Homepage Slider:</InputLabel>
            <Select
              id="is_homepage_slider"
              name="is_homepage_slider"
              value={formData.is_homepage_slider}
              onChange={handleInputChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          {formData.is_homepage_slider == true &&
            <TextField
              fullWidth
              label="Title (Image Description on Home Page)"
              id="Title"
              name="Title"
              value={formData.Title}
              onChange={handleInputChange}
              sx={{ mt: 2 }}
            />
          }


          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{
              position: 'absolute',
              background: 'white',
              zIndex: 1,
              border: formData.is_homepage_slider ? '1px solid #000' : 'none',
            }} htmlFor="business_type_id">Project Type:</InputLabel>

            <Select
              id="business_type_id"
              name="business_type_id"
              value={formData.business_type_id}
              onChange={handleInputChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '200px',
                  },
                },
              }}
            >
              {allProjectTypes?.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {
                      props.selected_lang === 'AL' ? el.type_al :
                        props.selected_lang === 'EN' ? el.type_en :
                          props.selected_lang === 'DE' ? el.type_de : ''
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{
              position: 'absolute',
              background: 'white',
              zIndex: 1,
              border: formData.is_homepage_slider ? '1px solid #000' : 'none',
            }} htmlFor="status_id">Status:</InputLabel>
            <Select
              id="status_id"
              name="status_id"
              value={formData.status_id}
              onChange={handleInputChange}
            >
              {allProjectStatuses?.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {
                      props.selected_lang === 'AL' ? el.status_al :
                        props.selected_lang === 'EN' ? el.status_en :
                          props.selected_lang === 'DE' ? el.status_de : ''
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Location (AL)"
                id="location_al"
                name="location_al"
                value={formData.location_al}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Location (EN)"
                id="location_en"
                name="location_en"
                value={formData.location_en}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Location (DE)"
                id="location_de"
                name="location_de"
                value={formData.location_de}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Object Name (AL)"
                id="obiject_name_al"
                name="obiject_name_al"
                value={formData.obiject_name_al}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Object Name (EN)"
                id="obiject_name_en"
                name="obiject_name_en"
                value={formData.obiject_name_en}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Object Name (DE)"
                id="obiject_name_de"
                name="obiject_name_de"
                value={formData.obiject_name_de}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>

          <input
            type="file"
            accept="image/*"
            id="kontenti_imazhi_kontentit"
            name="kontenti_imazhi_kontentit"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <input
                type="file"
                accept="image/*"
                id="kontenti_imazhi_kontentit"
                name="kontenti_imazhi_kontentit"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />
              <label htmlFor="kontenti_imazhi_kontentit">
                <Button component="span" variant="outlined" color="primary" fullWidth>
                  Upload Content Image
                </Button>
              </label>

              {/* Display the uploaded content image */}
              {handleImagePreview(formData.kontenti_imazhi_kontentit)}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Content Details (AL)"
                id="kontenti_detajet_e_projektit_al"
                name="kontenti.detajet_e_projektit_al"
                value={formData.kontenti.detajet_e_projektit_al}
                onChange={handleInputChange}
              />
            </Grid>
            {/* {console.log('form data vllaqkoi', formData)} */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Content Details (EN)"
                id="kontenti_detajet_e_projektit_en"
                name="kontenti.detajet_e_projektit_en"
                value={formData.kontenti.detajet_e_projektit_en}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Content Details (DE)"
                id="kontenti_detajet_e_projektit_de"
                name="kontenti.detajet_e_projektit_de"
                value={formData.kontenti.detajet_e_projektit_de}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <FormControl sx={{ mt: 2 }}>
            <FormLabel>Materials:</FormLabel>

            {/* CHECKBOXES */}

            <FormGroup>
              {allProjectMaterials?.map(el =>
                < FormControlLabel
                  control={
                    < Checkbox
                      checked={formData.kontenti.materialet.includes(`${el?.id}`)}
                      onChange={handleCheckboxChange}
                      name="kontenti.materialet"
                      value={el?.id}
                    />
                  }
                  label={
                    props.selected_lang === 'AL' ? el.material_al :
                      props.selected_lang === 'EN' ? el.material_en :
                        props.selected_lang === 'DE' ? el.material_de : ''
                  }
                />
              )}
            </FormGroup>
          </FormControl>
          {/* CHECKBOXES */}
          <hr />
          <h3>Project Gallery</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <input
                type="file"
                accept="image/*"
                id="gallery-images"
                name="gallery-images"
                multiple
                onChange={handleGalleryImageUpload}
                style={{ display: 'none' }}
              />
              <label htmlFor="gallery-images">
                <Button component="span" variant="outlined" color="primary" fullWidth>
                  Upload Gallery Images
                </Button>
              </label>
            </Grid>
            <hr />
            {formData.galleria.map((image, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card onClick={() => handleImageClick(image)}>
                  <CardMedia
                    component="img"
                    alt="Gallery Image"
                    height="140"
                    image={image instanceof Blob ? URL.createObjectURL(image) : ''}
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {image.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={props?.handleModalClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    logged_in: state.logged_in,
    auth: state.auth,
    selected_lang: state.selected_lang,
    langData: state.langData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setLogIn: (data) => dispatch(setLogIn(data)),
    // setAuth: (data) => dispatch(setAuth(data)),
    // setSelectedLang: (data) => dispatch(setSelectedLang(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectsModal);
// export default AddProjectsModal;
