import React from 'react'
import "./singleProduct.scss"
import { useEffect } from 'react';
import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Translations from '../../../components/translations/translations';

// import PVCpdf from "../../../katallogjet/Katallogu_Produktet_PVC.pdf"
// import GlassPDF from "../../../katallogjet/Katallogu_Produktet_PVC.pdf"
// import AluminiumPDF from "../../../katallogjet/Katallogu_Produktet_PVC.pdf"
import Slider from "react-slick";
import { useState } from 'react';
// import thisImage from "../../../images/singleProductsGalleries/Alumin/ALU_1.jpg"
import singleProductGallery from "../../../DB/pvcGallery.json"
import { useLocation } from 'react-router-dom'
import TranslationModal from '../../translatePagesModal/translationModal';

import pvcImage from "../../../assets/images/ourProducts/singleProducts/pvc/M-technologie-PVC-Product-2.png"
import pvcBanner from "../../../assets/images/ourProducts/singleProducts/pvc/baneri-pvc.png"
import imazhiRoletave from "../../../assets/images/ourProducts/singleProducts/pvc/roletaPVC.png"

import aluminBanner from "../../../assets/images/ourProducts/singleProducts/alumin/baneri-alumin.png"
import aluminImage1 from "../../../assets/images/ourProducts/singleProducts/alumin/Produktet-e-Aluminit.jpg"
import aluminImage2 from "../../../assets/images/ourProducts/singleProducts/alumin/alumin2.png"
import SpiderFasade from "../../../assets/images/ourProducts/singleProducts/alumin/Spider-fasade.jpg"
import fasadeventiluar from "../../../assets/images/ourProducts/singleProducts/alumin/fasadeventiluar.jpg"
import fasadengapaneletkompozite from "../../../assets/images/ourProducts/singleProducts/alumin/fasade-nga-panelet-kompozite.jpg"
import fasadeguri from "../../../assets/images/ourProducts/singleProducts/alumin/fasade-guri.jpg"
import fasadengaqeramika from "../../../assets/images/ourProducts/singleProducts/alumin/fasade-nga-qeramika.jpg"

import glassBannerImage from "../../../assets/images/ourProducts/singleProducts/glass/baneri-xham.png"
import smallGlassImage from "../../../assets/images/ourProducts/singleProducts/glass/Spider-Glass.png"
import Dekorative from "../../../assets/images/ourProducts/singleProducts/glass/Dekorative.jpg"
import Eksterier from "../../../assets/images/ourProducts/singleProducts/glass/Eksterier.jpg"
import Enterier from "../../../assets/images/ourProducts/singleProducts/glass/Enterier.jpg"
import jepiVenditNgjyre from "../../../assets/images/ourProducts/singleProducts/glass/Jepi-ngjyrë-ambientit-tuaj.jpg"
import Printimdigjitalnëxham from "../../../assets/images/ourProducts/singleProducts/glass/Printim-digjital-në-xham.jpg"
import glassBathroom from "../../../assets/images/ourProducts/singleProducts/glass/glassBathroom.png"

const SingleProduct = (props) => {

    const [isOpen, setOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);

    const handleEditLabelModal = (key) => {
        setOpen(true);
        setDataToModal(key)
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };

    let productType = useLocation().pathname.split("/").pop();
    // console.log("productType", productType)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [imageIndex, setImageIndex] = useState(1)

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    var slickSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) =>
            setImageIndex(current + 1),
        // this.setImageIndex({ oldSlide: current, activeSlide: next }),
        afterChange: current => setImageIndex(current + 1)
        // this.setImageIndex({ activeSlide2: current })

    };

    return (
        <>
            {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}

            {productType == "PVC" ?
                <div className='single_product_layout'>
                    <div className="banner_layout">
                        <img src={pvcBanner} className="single_product_image_banner img-fluid" alt="..." />
                        <div className="container banner_description">
                            <p className='col' onClick={() => { handleEditLabelModal("QëndrueshmëriElartëDheMirëmbajtjeElehtë") }}>{Translations(props?.langData, props?.selected_lang, 'QëndrueshmëriElartëDheMirëmbajtjeElehtë')}</p>
                        </div>
                    </div>
                    <div className="container single_product_description">
                        <div className="row">
                            <div className="col-md-8">
                                <p className="single_product_title" onClick={() => { handleEditLabelModal("ProduktetNgaPVC") }}>{Translations(props?.langData, props?.selected_lang, 'ProduktetNgaPVC')}</p>
                                <p className="product_text" onClick={() => { handleEditLabelModal("mekanizmaTeProdhimitPVC") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'mekanizmaTeProdhimitPVC')}` }} />
                            </div>
                            <div className="col-md-4">
                                <img src={pvcImage} className="img-fluid col-12" alt="" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            {/* <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'TeknologjiaEprodhimit')}</p>
                            <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'pershkrimiTeknologjiveTeProdhimitPVC')}` }} /> */}
                            {/* <img src={llojetEdritareve} className="img-fluid col-md-12" alt="" /> */}
                            {/* <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'MekanizmiLabel')}</p>
                            <p className="product_text col-md-12 pb-1" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'mekanizmaTeProdhimitPVC')}` }} /> */}
                            <div className="row  justify-content-around mt-5 pb-5">
                                <div className="col-md-4">
                                    <img src={imazhiRoletave} className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <p className="single_product_smaller_title mt-3" onClick={() => { handleEditLabelModal("RolentetROLAPLUS") }}>{Translations(props?.langData, props?.selected_lang, 'RolentetROLAPLUS')}</p>
                                    <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("roletatTeProdhimitPVC") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC')}` }} />
                                    <ul>
                                        <li className='product_text' onClick={() => { handleEditLabelModal("roletatTeProdhimitPVC-li1") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li1')}` }} />
                                        <li className='product_text' onClick={() => { handleEditLabelModal("roletatTeProdhimitPVC-li2") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li2')}` }} />
                                        <li className='product_text' onClick={() => { handleEditLabelModal("roletatTeProdhimitPVC-li3") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li3')}` }} />
                                        <li className='product_text' onClick={() => { handleEditLabelModal("roletatTeProdhimitPVC-li4") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li4')}` }} />
                                        <li className='product_text' onClick={() => { handleEditLabelModal("roletatTeProdhimitPVC-li5") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li5')}` }} />
                                        <li className='product_text' onClick={() => { handleEditLabelModal("roletatTeProdhimitPVC-li6") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li6')}` }} />
                                        <li className='product_text' onClick={() => { handleEditLabelModal("roletatTeProdhimitPVC-li7") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li7')}` }} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="galleria pt-5 pb-5  ">
                        <div className="container">
                            <div className="row">
                                <p className='galleria_title col-6' onClick={() => { handleEditLabelModal("GaleriaLabel") }}>{Translations(props?.langData, props?.selected_lang, 'GaleriaLabel')}</p>
                                <p className='galleria_image_number col-6'>{imageIndex}/{singleProductGallery[0]?.PVC?.length}</p>
                            </div>
                            <Slider {...slickSettings}>
                                {singleProductGallery[0]?.PVC?.map((el, index) => {
                                    return (

                                        <img key={index} src={el.img_src}
                                            alt={el?.img_alt}
                                            className="single_project_slider"
                                        />

                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                    {/* <div className="container mt-5 mb-5 partneretTane">
                        {ourPartners.map((el, index) => {
                            return (
                                <img data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={`../.${el?.img_src}`} alt={el.title} />
                            )
                        })}
                    </div> */}
                </div >
                :
                productType == "Alumin" ?
                    <div className='single_product_layout'>
                        <div className="banner_layout">
                            <img src={aluminBanner} className="single_product_image_banner img-fluid" alt="..." />
                            <div className="container banner_description">
                                <p className='col ' onClick={() => { handleEditLabelModal("NdjehuKomodMeProduktetTonaKualitative") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'NdjehuKomodMeProduktetTonaKualitative')}` }}></p>
                            </div>
                        </div>
                        <div className="container single_product_description">
                            <div className="row justify-content-around">
                                <div className="col-md-8">
                                    <p className="single_product_title " onClick={() => { handleEditLabelModal("ProduktetNgaAlumini") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ProduktetNgaAlumini')}` }}></p>
                                    <p className="product_text" onClick={() => { handleEditLabelModal("pershkrimiPareProduktitAlumini") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'pershkrimiPareProduktitAlumini')}` }} />
                                </div>
                                <div className="col-md-4">
                                    <img src={aluminImage1} className="img-fluid col-10" alt="" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="single_product_smaller_title mt-3" onClick={() => { handleEditLabelModal("TitulliDyerDheDritareProduktitAlumini") }} >{Translations(props?.langData, props?.selected_lang, 'TitulliDyerDheDritareProduktitAlumini')}</p>
                                <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("pershkrimiDyerDheDritareProduktitAlumini") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'pershkrimiDyerDheDritareProduktitAlumini')}` }} />

                                <div className="row  justify-content-around mt-5 pb-5">
                                    <div className="col-md-4">
                                        <img src={aluminImage2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <p className="single_product_smaller_title mt-3" onClick={() => { handleEditLabelModal("FasadaNgaAluminiTitle") }} >{Translations(props?.langData, props?.selected_lang, 'FasadaNgaAluminiTitle')}</p>
                                        <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("fasadaNgaAluminiDescription") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'fasadaNgaAluminiDescription')}` }} />
                                        <img src={SpiderFasade} className="img-fluid w-50 mb-3" alt="" />
                                        <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("spiderFasadaTitle") }} >{Translations(props?.langData, props?.selected_lang, 'spiderFasadaTitle')}</p>
                                        <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("spiderFasadaDescription") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'spiderFasadaDescription')}` }} />
                                        <img src={fasadeventiluar} className="img-fluid w-50 mb-3" alt="" />

                                        <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("fasadEventiluarTitle") }} >{Translations(props?.langData, props?.selected_lang, 'fasadEventiluarTitle')}</p>
                                        <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("fasadEventiluarDescription") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'fasadEventiluarDescription')}` }} />
                                        <img src={fasadengapaneletkompozite} className="img-fluid w-50 mb-3" alt="" />

                                        <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("FasadaNgaPaneleKompoziteAluminTitle") }} >{Translations(props?.langData, props?.selected_lang, 'FasadaNgaPaneleKompoziteAluminTitle')}</p>
                                        <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("FasadaNgaPaneleKompoziteAluminDescription") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'FasadaNgaPaneleKompoziteAluminDescription')}` }} />
                                        <img src={fasadeguri} className="img-fluid w-50 mb-3" alt="" />
                                        <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("FasadëNgaGuriTitle") }} >{Translations(props?.langData, props?.selected_lang, 'FasadëNgaGuriTitle')}</p>
                                        <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("FasadëNgaGuriDescription") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'FasadëNgaGuriDescription')}` }} />
                                        <img src={fasadengaqeramika} className="img-fluid w-50 mb-3" alt="" />
                                        <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("FasadëNgaQeramikaTitle") }} >{Translations(props?.langData, props?.selected_lang, 'FasadëNgaQeramikaTitle')}</p>
                                        <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("FasadëNgaQeramikaDescription") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'FasadëNgaQeramikaDescription')}` }} />
                                        <hr className='vijaNdarese col-12 mb-4 mt-4' />
                                        {/* <a href={AluminiumPDF} target="_blank" className='openPDFparagraph'><i className='fa-regular fa-file-pdf' /> {Translations(props?.langData, props?.selected_lang, 'shikoKatalogunEprodukteveAluminSinglePage')}</a> */}
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="galleria pt-5 pb-5  ">

                            <div className="container-fluid">
                                <div className="row">
                                    <p className='galleria_title col-6' onClick={() => { handleEditLabelModal("GaleriaLabel") }} >{Translations(props?.langData, props?.selected_lang, 'GaleriaLabel')}</p>
                                    <p className='galleria_image_number col-6'>{imageIndex}/{singleProductGallery[0]?.alumin?.length}</p>
                                </div>
                                <Slider {...slickSettings}>
                                    {singleProductGallery[0]?.alumin?.map((el, index) => {
                                        return (

                                            <img key={index} src={el.img_src}
                                                alt={el?.img_alt}
                                                className="single_project_slider"
                                            />

                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                        {/* <div className="container mt-5 mb-5 partneretTane">
                            {ourPartners.map((el, index) => {
                                return (
                                    <img data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={`../.${el?.img_src}`} alt={el.title} />
                                )
                            })}
                        </div> */}
                    </div>
                    :
                    productType == "glass" ?
                        <div className='single_product_layout'>
                            <div className="banner_layout">
                                <img src={glassBannerImage} className="single_product_image_banner " alt="..." />
                                <div className="container banner_description">
                                    <p className='col ' onClick={() => { handleEditLabelModal("NdjehuKomodMeProduktetTonaKualitative") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'NdjehuKomodMeProduktetTonaKualitative')}` }} />
                                </div>
                            </div>
                            <div className="container single_product_description pt-5">
                                <div className="row">
                                    <div className="col-md-8">
                                        <p className="single_product_title" onClick={() => { handleEditLabelModal("ProduktetExhamitTitle") }}>{Translations(props?.langData, props?.selected_lang, 'ProduktetExhamitTitle')}</p>
                                        <p className="product_text" onClick={() => { handleEditLabelModal("ProduktetExhamitFirstDescription") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ProduktetExhamitFirstDescription')}` }} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src={smallGlassImage} className="img-fluid col-12" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-5">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("EksterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'EksterierLabel')}</p>
                                            <ul>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("FASADAXHAMISpiderLabel") }}>{Translations(props?.langData, props?.selected_lang, 'FASADAXHAMISpiderLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("FASADËDHELLANTERINËSPIDERLabel") }}>{Translations(props?.langData, props?.selected_lang, 'FASADËDHELLANTERINËSPIDERLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("MULESËÇATIE(STREHË)Label") }}>{Translations(props?.langData, props?.selected_lang, 'MULESËÇATIE(STREHË)Label')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("STREHËDHEMBËSHTETJENGAPOSHTË") }}>{Translations(props?.langData, props?.selected_lang, 'STREHËDHEMBËSHTETJENGAPOSHTË')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("STREHËDHEMBËSHTETJENGALARTËMELITAR") }}>{Translations(props?.langData, props?.selected_lang, 'STREHËDHEMBËSHTETJENGALARTËMELITAR')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("RRETHOJËEksterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËEksterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("RRETHOJËTERASEEksterierLable") }}>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËTERASEEksterierLable')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("RRETHOJËMEPULTEksterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËMEPULTEksterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("RRETHOJËMERAMJEEksterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËMERAMJEEksterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("RRETHOJËNDARËSEEksterieLabel") }}>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËNDARËSEEksterieLabel')}</li>
                                            </ul>
                                            <img src={Eksterier} className="img-fluid w-50 mb-3" alt="" />

                                        </div>
                                        <div className="col-md-4">
                                            <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("EnterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'EnterierLabel')}</p>
                                            <ul>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("DYEREnterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'DYEREnterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("NDARJETËHAPËSIRAVEEnterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'NDARJETËHAPËSIRAVEEnterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("DUSHKABINAEnterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'DUSHKABINAEnterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("MOBILIERIKUZHINAEnterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'MOBILIERIKUZHINAEnterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("TAVOLINËDOLLAPEETJEnterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'TAVOLINËDOLLAPEETJEnterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("XHAMPËRKABINAEnterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'XHAMPËRKABINAEnterierLabel')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("XHAMPËRASHENSORËEnterierLabel") }}>{Translations(props?.langData, props?.selected_lang, 'XHAMPËRASHENSORËEnterierLabel')}</li>
                                            </ul>
                                            <img src={Enterier} className="img-fluid w-75 mb-3" alt="" />

                                        </div>
                                        <div className="col-md-4">
                                            <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("DekorativeLabel") }}>{Translations(props?.langData, props?.selected_lang, 'DekorativeLabel')}</p>
                                            <ul>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("KREATIVREZINAdekorative") }}>{Translations(props?.langData, props?.selected_lang, 'KREATIVREZINAdekorative')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("XHAMMENGJYRËdekorative") }}>{Translations(props?.langData, props?.selected_lang, 'XHAMMENGJYRËdekorative')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("PRINTIMDIGJITALdekorative") }}>{Translations(props?.langData, props?.selected_lang, 'PRINTIMDIGJITALdekorative')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("Fasadëxhami(spider)dekorative") }}>{Translations(props?.langData, props?.selected_lang, 'Fasadëxhami(spider)dekorative')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("Mbulesëçatie(strehë)dekorative") }}>{Translations(props?.langData, props?.selected_lang, 'Mbulesëçatie(strehë)dekorative')}</li>
                                                <li className='product_text' onClick={() => { handleEditLabelModal("Rrethojëdekorative") }}>{Translations(props?.langData, props?.selected_lang, 'Rrethojëdekorative')}</li>
                                            </ul>
                                            <br />
                                            <img src={Dekorative} className="img-fluid w-75 mb-3" alt="" />

                                        </div>
                                    </div>
                                    <p className="single_product_smaller_title" onClick={() => { handleEditLabelModal("NdarjetëbrendshmeLabel") }}>{Translations(props?.langData, props?.selected_lang, 'NdarjetëbrendshmeLabel')}</p>
                                    <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("NdarjetëbrendshmeDescriptionText") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'NdarjetëbrendshmeDescriptionText')}` }} />
                                    <div className="row  justify-content-around mt-5 pb-5">
                                        <div className="col-md-4">
                                            <img src={glassBathroom} className="img-fluid mt-3" alt="" />
                                        </div>
                                        <div className="col-md-6">
                                            <p className="single_product_smaller_title mt-3" onClick={() => { handleEditLabelModal("ZgjidhjeSofistikuarPërBanjonTënde") }}>{Translations(props?.langData, props?.selected_lang, 'ZgjidhjeSofistikuarPërBanjonTënde')}</p>
                                            <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("ZgjidhjeSofistikuarPërBanjonTëndeTeksti") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ZgjidhjeSofistikuarPërBanjonTëndeTeksti')}` }} />
                                            <p className="single_product_smaller_title mt-3" onClick={() => { handleEditLabelModal("JepiNgjyrëAmbientitTuaj") }}>{Translations(props?.langData, props?.selected_lang, 'JepiNgjyrëAmbientitTuaj')}</p>
                                            {/* <img src={jepiVenditNgjyre} className="img-fluid w-75 mb-3" alt="" /> */}

                                            <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("JepiNgjyrëAmbientitTuajTeksti") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'JepiNgjyrëAmbientitTuajTeksti')}` }} />
                                            <p className="single_product_smaller_title mt-3" onClick={() => { handleEditLabelModal("PrintimDigjitalnëXham") }}>{Translations(props?.langData, props?.selected_lang, 'PrintimDigjitalnëXham')}</p>
                                            {/* <img src={Printimdigjitalnëxham} className="img-fluid w-75 mb-3" alt="" /> */}
                                            <p className="product_text col-md-12" onClick={() => { handleEditLabelModal("PrintimDigjitalnëXhamTeksti") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'PrintimDigjitalnëXhamTeksti')}` }} />
                                            <hr className='vijaNdarese col-12 mb-4 mt-4' />
                                            {/* <a href={GlassPDF} target="_blank" className='openPDFparagraph'><i className='fa-regular fa-file-pdf' /> {Translations(props?.langData, props?.selected_lang, 'shikoKatalogunEprodukteveXhamitSinglePage')}</a> */}
                                            {/* <ul>
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li1')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li2')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li3')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li4')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li5')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li6')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li7')}` }} />
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="galleria pt-5 pb-5  ">

                                <div className="container-fluid">
                                    <div className="row">
                                        <p className='galleria_title col-6' onClick={() => { handleEditLabelModal("GaleriaLabel") }}>{Translations(props?.langData, props?.selected_lang, 'GaleriaLabel')}</p>
                                        <p className='galleria_image_number col-6'>{imageIndex}/{singleProductGallery[0]?.XHAM?.length}</p>
                                    </div>
                                    <Slider {...slickSettings}>
                                        {singleProductGallery[0]?.XHAM?.map((el, index) => {
                                            return (

                                                <img key={index} src={el.img_src}
                                                    alt={el?.img_alt}
                                                    className="single_project_slider"
                                                />

                                            )
                                        })}
                                    </Slider>
                                </div>
                            </div>
                            {/* <div className="container mt-5 mb-5 partneretTane">
                                {ourPartners.map((el, index) => {
                                    return (
                                        <img data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={`../.${el?.img_src}`} alt={el.title} />
                                    )
                                })}
                            </div> */}
                        </div>
                        :
                        ""
            }
        </>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: () => dispatch(setLangData()),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);