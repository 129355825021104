import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Card,
  CardContent,
  CardMedia,
  Box,
  Typography,
  Checkbox,
  FormGroup,
  CardActions,
} from '@mui/material';
import axios from '../../../axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

function EditProjectsContentModal(props) {
  // console.log('popo kjo arton', props)
  const [updateMaterials, setUpdateMaterial] = useState()

  const [formData, setFormData] = useState({
    id: props?.dataToModal?.id,
    alt: props?.dataToModal?.alt,
    bg_img: `${process.env.REACT_APP_API}/${props?.dataToModal?.bg_img}`,
    title: props?.dataToModal?.title,
    is_homepage_slider: props?.dataToModal?.is_homepage_slider,
    business_type_id: props?.dataToModal?.businessType?.id,
    status_id: props?.dataToModal?.project_status?.id,
    obiject_name_al: props?.dataToModal?.obiject_name_al,
    obiject_name_en: props?.dataToModal?.obiject_name_en,
    obiject_name_de: props?.dataToModal?.obiject_name_de,
    location_al: props?.dataToModal?.location_al,
    location_en: props?.dataToModal?.location_en,
    location_de: props?.dataToModal?.location_de,
    kontenti_imazhi_kontentit: `${process.env.REACT_APP_API}/${props?.dataToModal?.project_content?.content_img}`,
    kontenti: {
      id: props?.dataToModal?.project_content?.id,
      detajet_e_projektit_al: props?.dataToModal?.project_content?.project_details_al,
      detajet_e_projektit_en: props?.dataToModal?.project_content?.project_details_en,
      detajet_e_projektit_de: props?.dataToModal?.project_content?.project_details_de,
      materialet: [props?.dataToModal?.project_content?.content_materials]
    },
    galleria: props?.dataToModal?.project_gallery
  });
  const [selectedMaterials, setSelectedMaterials] = useState(
    (props?.dataToModal?.project_content?.content_materials || []).map((el) => el?.id) || []
  );
  // console.log('edit arton formData aaaaaaa', updateMaterials)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData };
    const keys = name.split('.');

    let current = updatedData;

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      current[key] = current[key] || {};
      current = current[key];
    }


    current[keys[keys.length - 1]] = value;
    setFormData(updatedData);
  };



  const toggleMaterial = (materialId) => {
    setSelectedMaterials((prevSelectedMaterials) => {
      const isSelected = prevSelectedMaterials.includes(materialId);
      if (isSelected) {
        return prevSelectedMaterials.filter((id) => id !== materialId);
      } else {
        return [...prevSelectedMaterials, materialId];
      }
    });
    // console.log('Selected Materials:', selectedMaterials);
  };
  // console.log('Selected Materials: jasht :: ', selectedMaterials);

  useEffect(() => {
    const contentMaterials = (props?.dataToModal?.project_content?.content_materials || []).map(
      (el) => el?.id
    );
    const materialsToAdd = selectedMaterials.filter((materialId) => !contentMaterials.includes(materialId));
    const materialsToDelete = contentMaterials.filter(
      (materialId) => !selectedMaterials.includes(materialId)
    );
    let update = {
      materialsToAdd,
      materialsToDelete,
    };
    setUpdateMaterial(update)
    // console.log('Payload gashi:', updateMaterials);

  }, [selectedMaterials]);



  const handleDeleteGalleryImage = (dataToToggle) => {
    setFormData((prevFormData) => {
      const galleriaDelete = prevFormData?.galleria_delete || [];

      if (galleriaDelete?.includes(dataToToggle)) {
        return {
          ...prevFormData,
          galleria_delete: galleriaDelete.filter((item) => item !== dataToToggle),
        };
      } else {
        // If the data doesn't exist in the array, add it
        return {
          ...prevFormData,
          galleria_delete: [...galleriaDelete, dataToToggle],
        };
      }
    });
  };
  const handleDeleteNewGalleryImage = (dataToToggle) => {
    setFormData((prevFormData) => {
      const galleriaCancelAdd = [...(prevFormData.galleria_new || [])];
      const galleria = [...(prevFormData.galleria || [])];
      const indexInGalleriaCancelAdd = galleriaCancelAdd.indexOf(dataToToggle);
      const indexInGalleria = galleria.indexOf(dataToToggle);

      if (indexInGalleriaCancelAdd !== -1) {
        galleriaCancelAdd.splice(indexInGalleriaCancelAdd, 1);
      }

      if (indexInGalleria !== -1) {
        galleria.splice(indexInGalleria, 1);
      }

      return {
        ...prevFormData,
        galleria_new: galleriaCancelAdd,
        galleria: galleria,
      };
    });
  };


  const handleImageUpload = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];

    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleGalleryImageUpload = (e) => {
    const files = e.target.files;
    let updatedGalleria = [];

    for (let i = 0; i < files.length; i++) {
      updatedGalleria.push(files[i]);
    }

    setFormData({
      ...formData,
      galleria: [...formData.galleria, ...updatedGalleria],
      galleria_new: updatedGalleria,
    });
  };

  const handleEdit = () => {
    const formDataToSend = new FormData();

    for (const key in formData) {
      if (key === 'kontenti') {
        formDataToSend.append(key, JSON.stringify(formData[key]));
      }
      else if (key === 'galleria') {
        continue
      }
      else if (key === 'galleria_new') {
        for (const image of formData[key]) {
          formDataToSend.append('galleria_add', image);
        }
      }
      else if (key === 'galleria_delete') {
        for (const image of formData[key]) {
          formDataToSend.append('galleria_delete', image);
        }
      }
      else {
        formDataToSend.append(key, formData[key]);
      }
    }
    formDataToSend.append('updateMaterials', JSON.stringify(updateMaterials));

    axios.put(`api/admin/projects/update/${props?.dataToModal?.id}`, formDataToSend)
      .then((res) => {
        toast.success(`Projekti u editua me sukses!`);
        // console.log('Response:', res);
        // props?.getAllProjects();
        // props?.handleModalClose();
      })
      .catch((err) => {
        toast.error(`Error: ${err}`);
        console.error('Error:', err);
      })
      .finally(() => {
        // console.log('Form Data:', formData);
      });
  };

  const [allProjectTypes, setAllProjectTypes] = useState()
  const [allProjectStatuses, setAllProjectStatuses] = useState()
  const [allProjectMaterials, setAllProjectMaterials] = useState()

  function getProjectTypes() {
    axios.get('/api/admin/project_types/all')
      .then(res => setAllProjectTypes(res.data))
      .catch(err => {
        toast.error(`Error getting project types`)
        console.log(err)
      })
  }
  function getProjectStatuses() {
    axios.get('/api/admin/project_status/all')
      .then(res => setAllProjectStatuses(res.data))
      .catch(err => {
        toast.error(`Error getting project types`)
        console.log(err)
      })
  }
  function getProjectMaterials() {
    axios.get('/api/admin/project_materials/all')
      .then(res => setAllProjectMaterials(res.data))
      .catch(err => {
        toast.error(`Error getting project types`)
        console.log(err)
      })
  }
  useEffect(() => {
    getProjectTypes()
    getProjectStatuses()
    getProjectMaterials()
  }, [])




  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImagePreview = (file) => {
    const openImageModal = (file) => {
      // console.log('e qeli ', file)
      setSelectedImage(file);
    };
    const closeImageModal = () => {
      setSelectedImage(null);
    };
    if (file) {
      try {
        new URL(file);
        return (
          <div onClick={() => openImageModal(file)}>
            <Card>
              <CardMedia
                role='button'
                component="img"
                alt="Uploaded Image"
                height="140"
                src={file}
              />

            </Card>
          </div>
        );
      } catch (error) {
        if (file) {
          // console.log("filefilefilefilefile", file)
          return (
            <div onClick={() => openImageModal(file)}>

              <Card>
                <CardMedia

                  role='button'
                  component="img"
                  alt="Uploaded Image"
                  height="140"
                  src={URL.createObjectURL(file)}
                />

              </Card>
            </div>
          );
        }
      }
    }
    if (selectedImage) {
      { alert('po ktu') }
      return (
        <Dialog
          open={selectedImage !== null}
          onClose={closeImageModal}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Uploaded Image"
              style={{ width: '100%', maxHeight: '70vh', objectFit: 'contain' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeImageModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  };

  // const [selectedImage, setSelectedImage] = useState(null);
  // const openImageModal = (file) => {
  //   console.log('po erdhq qikjo', file)
  //   setSelectedImage(file?.img_url ? file?.img_url : file);
  // };

  // const closeImageModal = () => {
  //   setSelectedImage(null);
  // };
  // const handleImagePreview = (file) => {
  //   if (file) {
  //     try {
  //       new URL(file); // If successful, 'file' is a URL
  //       return (
  //         <div onClick={() => openImageModal(file)}>
  //           <Card>
  //             <CardMedia
  //               role='button'
  //               component="img"
  //               alt="Uploaded Image"
  //               height="140"
  //               src={file}
  //             />

  //           </Card>
  //         </div>
  //       );
  //     } catch (error) {
  //       if (file) {
  //      console.log("filefilefilefilefile", file)
  //         return (
  //           <div onClick={() => openImageModal(file)}>
  //             <Card>
  //               <CardMedia
  //                 component="img"
  //                 alt="Uploaded Image"
  //                 height="140"
  //                 src={URL.createObjectURL(file)}
  //               />

  //             </Card>
  //           </div>
  //         );
  //       }
  //     }
  //   }

  //   return null;
  // };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={props?.handleModalOpen}>
        Open Modal
      </Button> */}
      <Dialog open={props?.isOpen} onClose={props?.handleModalClose} maxWidth="lg">
        <DialogTitle>Insert Data</DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <input
            type="file"
            accept="image/*"
            id="bg_img"
            name="new_bg_img"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <label htmlFor="bg_img">
            <Button component="span" variant="outlined" color="primary">
              Upload Project Background Image
            </Button>
          </label>
          {!formData?.new_bg_img ? handleImagePreview(formData?.bg_img) : handleImagePreview(formData?.new_bg_img)}

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{
              position: 'absolute',
              background: 'white',
              zIndex: 1,
              border: formData.is_homepage_slider ? '1px solid #000' : 'none',
            }} htmlFor="is_homepage_slider">Is Homepage Slider:</InputLabel>
            <Select
              id="is_homepage_slider"
              name="is_homepage_slider"
              value={formData.is_homepage_slider}
              onChange={handleInputChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          {formData.is_homepage_slider == true &&
            <TextField
              fullWidth
              label="title (Image Description on Home Page)"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              sx={{ mt: 2 }}
            />
          }


          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{
              position: 'absolute',
              background: 'white',
              zIndex: 1,
              border: formData.is_homepage_slider ? '1px solid #000' : 'none',
            }} htmlFor="business_type_id">Project Type:</InputLabel>

            <Select
              id="business_type_id"
              name="business_type_id"
              value={formData.business_type_id}
              onChange={handleInputChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '200px',
                  },
                },
              }}
            >
              {allProjectTypes?.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {
                      props.selected_lang === 'AL' ? el.type_al :
                        props.selected_lang === 'EN' ? el.type_en :
                          props.selected_lang === 'DE' ? el.type_de : ''
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{
              position: 'absolute',
              background: 'white',
              zIndex: 1,
              border: formData.is_homepage_slider ? '1px solid #000' : 'none',
            }} htmlFor="status_id">Status:</InputLabel>
            <Select
              id="status_id"
              name="status_id"
              value={formData.status_id}
              onChange={handleInputChange}
            >
              {allProjectStatuses?.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {
                      props.selected_lang === 'AL' ? el.status_al :
                        props.selected_lang === 'EN' ? el.status_en :
                          props.selected_lang === 'DE' ? el.status_de : ''
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Location (AL)"
                id="location_al"
                name="location_al"
                value={formData.location_al}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Location (EN)"
                id="location_en"
                name="location_en"
                value={formData.location_en}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Location (DE)"
                id="location_de"
                name="location_de"
                value={formData.location_de}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Object Name (AL)"
                id="obiject_name_al"
                name="obiject_name_al"
                value={formData.obiject_name_al}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Object Name (EN)"
                id="obiject_name_en"
                name="obiject_name_en"
                value={formData.obiject_name_en}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Object Name (DE)"
                id="obiject_name_de"
                name="obiject_name_de"
                value={formData.obiject_name_de}
                onChange={handleInputChange}
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>

          <input
            type="file"
            accept="image/*"
            id="kontenti_imazhi_kontentit"
            name="new_kontenti_imazhi_kontentit"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <input
                type="file"
                accept="image/*"
                id="kontenti_imazhi_kontentit"
                name="kontenti_imazhi_kontentit"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />
              <label htmlFor="kontenti_imazhi_kontentit">
                <Button component="span" variant="outlined" color="primary" fullWidth>
                  Edit Content Image
                </Button>
              </label>

              {/* Display the uploaded content image */}
              {!formData?.new_kontenti_imazhi_kontentit ? handleImagePreview(formData?.kontenti_imazhi_kontentit) : handleImagePreview(formData?.new_kontenti_imazhi_kontentit)}

              {/* {handleImagePreview(formData.kontenti_imazhi_kontentit)} */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Content Details (AL)"
                id="kontenti_detajet_e_projektit_al"
                name="kontenti.detajet_e_projektit_al"
                value={formData.kontenti.detajet_e_projektit_al}
                onChange={handleInputChange}
              />
            </Grid>
            {/* {console.log('form data vllaqkoi', formData)} */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Content Details (EN)"
                id="kontenti_detajet_e_projektit_en"
                name="kontenti.detajet_e_projektit_en"
                value={formData.kontenti.detajet_e_projektit_en}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Content Details (DE)"
                id="kontenti_detajet_e_projektit_de"
                name="kontenti.detajet_e_projektit_de"
                value={formData.kontenti.detajet_e_projektit_de}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <FormControl sx={{ mt: 2 }}>
            <FormLabel>Materials:</FormLabel>

            {/* CHECKBOXES */}

            <FormGroup>
              {allProjectMaterials?.map(el =>

                < FormControlLabel
                  key={el.id}
                  control={
                    < Checkbox
                      checked={selectedMaterials?.includes(el?.id)}
                      onChange={() => toggleMaterial(el.id)}
                      name="kontenti.materialet"
                      value={el?.id}
                    />
                  }
                  label={
                    props.selected_lang === 'AL' ? el.material_al :
                      props.selected_lang === 'EN' ? el.material_en :
                        props.selected_lang === 'DE' ? el.material_de : ''
                  }
                >
                  {/* {console.log('first arton', selectedMaterials)} */}
                </FormControlLabel>
              )}

            </FormGroup>
          </FormControl>
          {/* CHECKBOXES */}
          <hr />
          <h3>Project Gallery</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <input
                type="file"
                accept="image/*"
                id="gallery-images"
                name="gallery-images"
                multiple
                onChange={handleGalleryImageUpload}
                style={{ display: 'none' }}
              />
              <label htmlFor="gallery-images">
                <Button component="span" variant="outlined" color="primary" fullWidth>
                  Upload Gallery Images
                </Button>
              </label>
            </Grid>
            <hr />
            {formData.galleria.map((image, index) => (

              // {console.log('imageeeee', image)}
              image?.img_url ?
                <Grid item xs={12} sm={4} key={index} >
                  <Card
                    //  onClick={() => handleImagePreview(image)} 
                    style={{
                      border: image.img_url
                        ? formData?.galleria_delete?.find(elm => elm === image.img_url)
                          ? 'solid 3px red'
                          : 'solid 3px black'
                        : 'solid 3px green'
                    }}>

                    <CardMedia
                      component="img"
                      alt="Gallery Image"
                      height="140"
                      image={`${process.env.REACT_APP_API}/${image.img_url}`}
                    />
                    {formData?.galleria_delete?.find(elm => elm == image.img_url) ?
                      <CardContent style={{ position: "relative" }} >
                        <Typography variant="body2" color="text.primary"  > ‎
                        </Typography>
                        <Typography variant="body2" color="text.primary" style={{ position: "absolute", color: "red", fontSize: "24px", left: '50%', transform: "translate(-50%, -50%)" }} >
                          Will delete
                        </Typography>
                      </CardContent>
                      :
                      <CardContent className='d-flex justify-content-between' >
                        <Typography variant="body2" color="text.secondary">
                          {formData?.obiject_name_al + ' - ' + index}
                        </Typography>
                        <Typography variant="body2" color="text.primary" >
                          Existing image
                        </Typography>
                      </CardContent>
                    }
                    <CardActions className='justify-content-center'>
                      <Button size="medium" color="secondary" onClick={() => handleDeleteGalleryImage(image.img_url)}>
                        {formData?.galleria_delete?.find(elm => elm == image.img_url) ? "Cancel deletion" : "Delete"}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                :
                <Grid item xs={12} sm={4} key={index} >
                  <Card
                    //  onClick={() => handleImagePreview(image)} 
                    style={{ border: 'solid 3px green', }}>
                    {/* {console.log("image ramadani upload", image)} */}
                    <CardMedia
                      component="img"
                      alt="Gallery Image"
                      height="140"
                      src={URL.createObjectURL(image)}
                    />
                    <CardContent style={{ position: "relative" }} >
                      <Typography variant="body2" color="text.primary"  > ‎
                      </Typography>
                      <Typography variant="body2" color="text.primary" style={{ position: "absolute", color: "green", fontSize: "24px", left: '50%', transform: "translate(-50%, -50%)" }} >
                        To be uploaded
                      </Typography>
                    </CardContent>
                    <CardActions className='justify-content-center'>
                      <Button size="small" color="secondary" onClick={() => handleDeleteNewGalleryImage(image)}>
                        Cancel
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={props?.handleModalClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEdit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    // logged_in: state.logged_in,
    // auth: state.auth,
    selected_lang: state.selected_lang,
    langData: state.langData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setLogIn: (data) => dispatch(setLogIn(data)),
    // setAuth: (data) => dispatch(setAuth(data)),
    // setSelectedLang: (data) => dispatch(setSelectedLang(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectsContentModal);
// export default EditProjectsContentModal;
