import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,

} from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import Box from '@mui/joy/Box';

import { toast } from 'react-toastify';
import axios from '../../../axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import { Editor } from '@tinymce/tinymce-react';




const AddNews = (props) => {
  console.log('props modal news', props)
  const AppApi = process.env.REACT_APP_API


  const [altText, setAltText] = useState('');

  const [image, setImage] = useState(props?.dataToModal?.img_url ? AppApi + '/' + props?.dataToModal?.img_url : null);
  const [newsTitle, setNewsTitle] = useState(props?.dataToModal?.title || "");
  const [location, setLocation] = useState(props?.dataToModal?.location || "");
  const [newsDate, setNewsDate] = useState(dayjs(new Date(props?.dataToModal?.date?.slice(0, 10))) || new Date());
  const [text, setText] = useState(props?.dataToModal?.news_text || "");
  const [imagePreview, setImagePreview] = useState(props?.dataToModal?.img_url ? true : false);
  let textToEditTinyMCE = props?.dataToModal?.news_text?.replace(/<p class="desc_heading">/g, "<strong>")?.replace(/<\/p>/g, "</strong>");
  // const handleCheckboxChange = () => {
  //   setIsHighManagement(!isHighManagement);
  // };

  const handleImageUpload = (image) => {
    console.log("image", image)
    if (typeof image == 'object') {
      if (image.target.files[0]) {
        const selectedImage = image.target.files[0];
        setImage(selectedImage);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(selectedImage);
      }
    } else if (typeof image === 'string') {
      setImagePreview(AppApi + '/' + image);
    }
  };

  useEffect(() => {
    if (props?.dataToModal?.img_url) {
      handleImageUpload(props?.dataToModal?.img_url);
    }
  }, [props?.dataToModal?.img_url]);

  const handleImagePost = () => {
    let errorMessage = '';
  
    // Validation using switch statement
    switch (true) {
      case !image:
        errorMessage = 'Please select an image.';
        break;
      case !newsDate:
        errorMessage = 'Please select a date.';
        break;
      case !newsTitle || !location || !text:
        errorMessage = 'Please fill in all fields.';
        break;
      default:
        break;
    }
  
    // If errorMessage is set, display error message and return
    if (errorMessage) {
      return toast.info(errorMessage);
    }
  
    // Form data preparation
    const formData = new FormData();
    formData.append('news_image', image);
    formData.append('date', new Date(newsDate).toISOString());
    formData.append('title', newsTitle);
    formData.append('location', location);
    formData.append('news_text', text);
  
    // Form submission
    axios
      .post('/api/admin/news', formData)
      .then((res) => {
        props.handleGetPartners();
        toast.success('News uploaded successfully.');
        props.handleModalClose();
      })
      .catch((err) => {
        console.error('Error uploading image', err);
        toast.error('Error uploading image.');
      });
  };
  
  const handleNewsEdit = () => {
    // if (!image) {
    //   console.log("newsDate post ", new Date(newsDate).toISOString())
    //   toast.error('Please select an image.');
    //   return;
    // }

    const formData = new FormData();
    formData.append('news_image', image);
    formData.append('title', newsTitle);
    formData.append('location', location);
    formData.append('date', new Date(newsDate));
    formData.append('news_text', text);

    axios
      .put(`/api/admin/news/${props?.dataToModal?.id}`, formData)
      .then((res) => {
        props.handleGetPartners();
        toast.success('News uploaded successfully.');
        props.handleModalClose();
      })
      .catch((err) => {
        console.error('Error uploading image', err);
        toast.error('Error uploading image.');
      });
  };

  const handleDelete = () => {
    // Send a request to delete the partner here
    axios
      .delete(`/api/admin/news/${props?.dataToModal?.id}`)
      .then((res) => {
        // Handle successful deletion, e.g., update your partner list
        props.handleGetPartners();
        props.handleModalClose();
      })
      .catch((err) => {
        console.error('Error deleting partner', err);
        // Handle deletion error
      });
  };

  const [previewImage, setPreviewImage] = useState(props?.dataToModal?.img_url && true || false)


  function checkImageType(file) {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
    console.log("file", file)
    if (file && allowedTypes.includes(file.type)) {
      setPreviewImage(true)
      setImage(file ? file : null)
    } else {
      toast.info("Formatet e fotografive që pranohen janë .png, .jpg dhe .jpeg");
      setImage(null)
      setPreviewImage(false)
      return false;
    }
  }

  const editorRef = useRef(null);
  // useEffect(() => {
  //   const editor = editorRef.current;

  //   if (editor) {
  //     editor.on('change', function () {
  //       const content = editor.getContent();
  //       const formattedContent = applyClassToText(content);
  //       setText(formattedContent);
  //     });
  //   }

  //   return () => {
  //     if (editor) {
  //       editor.off('change');
  //     }
  //   };
  // }, []);

  const [editorValue, setEditorValue] = useState('');

  const handleEditorChange = async (content, editor) => {
    const formattedContent = await applyClassToText(content);
    setEditorValue(formattedContent);
    setText(formattedContent);
  };

  const applyClassToText = async (content) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    // Loop through each p tag in the div
    await tempDiv.querySelectorAll('p').forEach(p => {
      // Check if the p tag contains bold formatting
      const strongTags = p.querySelectorAll('strong');
      if (strongTags.length > 0) {
        strongTags.forEach(strong => {
          // Replace the strong tag with a new p tag
          const newPTag = document.createElement('p');
          newPTag.innerHTML = strong.innerHTML;
          newPTag.classList.add('desc_heading');
          strong.parentNode?.replaceChild(newPTag, strong);
        });
      } else {
        // Apply class desc_text
        p.classList.add('desc_text');
      }
    });

    return tempDiv.innerHTML;
  };



  console.log("editorValue", editorValue)
  return (
    <>{props?.modalType == "add"
      ?
      <Dialog open={props.isOpen} onClose={props.handleModalClose}>
        <DialogTitle>Upload News</DialogTitle>
        <DialogContent>
          <div>
            <div onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
              onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                checkImageType(e.dataTransfer.files[0]);
              }} class="d-flex rounded border align-items-center justify-content-center w-100 image_upload_container">

              <label for="dropzone-file" class="d-flex flex-column align-items-center justify-content-center border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                {!previewImage && (
                  <>
                    <div class="d-flex flex-column align-items-center justify-content-center pb-6">
                      <svg class="col-2 w-8 h-8 my-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                      </svg>
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-weight-bold">Ngarko fotografinë </span> <b>lajmit te ri</b> </p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, JPEG (MAX. 5MB)</p>
                    </div>   {console.log('qetuuuu')}
                    <input accept=".jpg, .jpeg, .png, .webp" id="dropzone-file" type="file" class="visually-hidden" onChange={(e) => checkImageType(e?.target?.files[0])} />
                  </>
                )}
                {previewImage && (
                  <>

                    <div class="d-flex flex-column align-items-center justify-content-center  pb-6">
                      <img id="uploaded-image" className="img-fluid mt-3 col-8 " src={URL?.createObjectURL(image)} alt="Uploaded" />
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-weight-bold">Ndrysho fotografinë</span></p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, JPEG (MAX. 5MB)</p>
                    </div>
                    <input id="dropzone-file" accept=".jpg, .jpeg, .png, .webp" type="file" class="visually-hidden" onChange={(e) => checkImageType(e?.target?.files[0])} />
                  </>
                )}
              </label>
            </div>


          </div>
          <div>
            <TextField
              label="News name"
              type="text"
              value={newsTitle}
              onChange={(e) => setNewsTitle(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Location"
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              fullWidth
              margin="normal"
            />


            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={[
                  'DatePicker',
                  'MobileDatePicker',
                  'DesktopDatePicker',
                  'StaticDatePicker',
                ]}
              >
                <DemoItem >
                  <DatePicker
                    label="News Date"
                    defaultValue={null}
                    onChange={(newValue) => setNewsDate(newValue)}

                    inputFormat="DD/MM/YYYY"
                  />

                  {/* <DatePicker
                    defaultValue={dayjs()}
                    value={newsDate}
                  
                    // slotProps={{
                    //   textField: {
                    //     helperText: 'DD/MM/YYYY',
                    //   },
                    // }}
                    onChange={(newValue) => setNewsDate(newValue)} /> */}
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>


            <div className="mb-3" />
            <Editor

              onInit={(evt, editor) => { editorRef.current = editor }}
              onEditorChange={handleEditorChange}

              initialValue=""
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}

            />


          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleModalClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleImagePost} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog >
      :
      props?.modalType == "edit" ?
        <Dialog open={props.isOpen} onClose={props.handleModalClose}>
          <DialogTitle>Edit News</DialogTitle>
          <DialogContent>
            {/* {imagePreview && (
              <div>
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  style={{ maxWidth: '100%', maxHeight: '300px' }}
                />
              </div>
            )} */}

            <div onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
              onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                checkImageType(e.dataTransfer.files[0]);
              }} class="d-flex rounded border align-items-center justify-content-center w-100 image_upload_container">
              <label for="dropzone-file" class="d-flex flex-column align-items-center justify-content-center border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                {!image && (
                  <>
                    <div class="d-flex flex-column align-items-center justify-content-center pb-6">
                      <svg class="col-2 w-8 h-8 my-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                      </svg>
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-weight-bold">Ngarko fotografinë </span> <b>lajmit te ri</b> </p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, JPEG (MAX. 5MB)</p>
                    </div>
                    <input accept=".jpg, .jpeg, .png, .webp" id="dropzone-file" type="file" class="visually-hidden" onChange={(e) => checkImageType(e?.target?.files[0])} />
                  </>
                )}
                {imagePreview && image instanceof Blob ? (
                  <>
                    {console.log("image ", image)}
                    <div class="d-flex flex-column align-items-center justify-content-center  pb-6">
                      <img id="uploaded-image" className="img-fluid mt-3 col-8 " src={URL?.createObjectURL(image)} alt="Uploaded" />
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-weight-bold">Ndrysho fotografinë</span></p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, JPEG (MAX. 5MB)</p>
                    </div>
                    <input id="dropzone-file" accept=".jpg, .jpeg, .png, .webp" type="file" class="visually-hidden" onChange={(e) => checkImageType(e?.target?.files[0])} />
                  </>
                ) :
                  <>
                    {console.log("image ", image)}
                    <div class="d-flex flex-column align-items-center justify-content-center  pb-6">
                      <img id="uploaded-image" className="img-fluid mt-3 col-8 " src={image} alt="Uploaded" />
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-weight-bold">Ndrysho fotografinë</span></p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, JPEG (MAX. 5MB)</p>
                    </div>
                    <input id="dropzone-file" accept=".jpg, .jpeg, .png, .webp" type="file" class="visually-hidden" onChange={(e) => checkImageType(e?.target?.files[0])} />
                  </>}
              </label>
            </div>
            <div>
              <TextField
                label="Full name"
                type="text"
                value={newsTitle}
                onChange={(e) => setNewsTitle(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Location"
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                fullWidth
                margin="normal"
              />


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'MobileDatePicker',
                    'DesktopDatePicker',
                    'StaticDatePicker',
                  ]}
                >
                  <DemoItem label="Date">
                    <DatePicker value={newsDate}
                      onChange={(newValue) => setNewsDate(newValue)} />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>

              <div className="mb-3" />
              <Editor

                onInit={(evt, editor) => { editorRef.current = editor }}
                onEditorChange={handleEditorChange}

                initialValue={textToEditTinyMCE}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}

              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleModalClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleNewsEdit} color="primary">
              Edit
            </Button>
          </DialogActions>
        </Dialog>
        :

        <Dialog open={props.isOpen} onClose={props.handleModalClose}>
          <DialogTitle>Delete News</DialogTitle>
          <DialogContent >
            <Typography variant="body1">
              Are you sure you want to delete this News?
            </Typography>
            <img style={{ width: "132px", textAlign: "center", margin: "auto", display: 'flex' }} src={`${process.env.REACT_APP_API}/${props?.dataToModal?.img_url}`} alt="" />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleModalClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete()} color="primary">
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
    }
    </>

  );
};

export default AddNews;
