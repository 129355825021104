import axios from '../../../../axios'
import React, { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@mui/material'; // Import the necessary components from @mui/material
import { toast } from 'react-toastify';


const AddStatusModal = (props) => {

    const [status_al, setStatuslAl] = useState(props?.dataToModal?.status_al || '');
    const [status_en, setStatuslEn] = useState(props?.dataToModal?.status_en || '');
    const [status_de, setStatuslDe] = useState(props?.dataToModal?.status_de || '');


    function handleMaterialPost() {
        let body = { status_al, status_en, status_de }
        axios.post("/api/admin/project_status", body)
            .then((res) => {
                toast.success("Statusi u krijua me sukses");
                props?.handleStatusGet();
                props?.handleModalClose();
                // console.log('u postu materiali', res)
            })
            .catch((err) => { console.log('u postu materiali', err) })
    }
    function handleMaterialEdit() {
        let body = { id: props?.dataToModal?.id, status_al, status_en, status_de }
        axios.put("/api/admin/project_status", body)
            .then((res) => {
                toast.success("Editimi u krye me sukses");
                props?.handleStatusGet();
                props?.handleModalClose();
                // console.log('u postu materiali', res)
            })
            .catch((err) => { console.log('nuk u editu materiali', err) })
    }
    function handleDelete(sid) {
        axios.delete(`/api/admin/project_status/${sid}`,)
            .then((res) => {
                toast.success("Statusi u fshi me sukses");
                props?.handleStatusGet();
                props?.handleModalClose();
                // console.log('u fshi materiali', res)
            })
            .catch((err) => { console.log('nuk u fshi project_status', err) })
    }
    // console.log('status props modal', props)
    return (
        <>{props?.modalType == "add" ?

            <Dialog open={props?.isOpen} onClose={props?.handleModalClose}>
                <DialogTitle>Insert Statuses</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            label="Status AL"
                            type="text"
                            value={status_al}
                            onChange={(e) => setStatuslAl(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                    <div>
                        <TextField
                            label="Status EN"
                            type="text"
                            value={status_en}
                            onChange={(e) => setStatuslEn(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                    <div>
                        <TextField
                            label="Status DE"
                            type="text"
                            value={status_de}
                            onChange={(e) => setStatuslDe(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props?.handleModalClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleMaterialPost} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            :
            props?.modalType == "edit" ?
                <Dialog open={props?.isOpen} onClose={props?.handleModalClose}>
                    <DialogTitle>Edit Status</DialogTitle>
                    <DialogContent>
                        <div>
                            <TextField
                                label="Material AL"
                                type="text"
                                value={status_al}
                                onChange={(e) => setStatuslAl(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div>
                            <TextField
                                label="Material EN"
                                type="text"
                                value={status_en}
                                onChange={(e) => setStatuslEn(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div>
                            <TextField
                                label="Material DE"
                                type="text"
                                value={status_de}
                                onChange={(e) => setStatuslDe(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props?.handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleMaterialEdit} color="primary">
                            Edit
                        </Button>
                    </DialogActions>
                </Dialog>

                :
                <Dialog open={props?.isOpen}>
                    <DialogTitle>Confirm Status Deletion</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            Are you sure you want to delete "{props?.dataToModal?.status_al}"?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props?.handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleDelete(props?.dataToModal?.id)} color="primary">
                            Yes, Delete
                        </Button>
                    </DialogActions>
                </Dialog>
        }
        </>
    )
}

export default AddStatusModal