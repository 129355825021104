import { useState } from 'react';
import './login.scss';
import axios from '../../axios';
import companyImage from '../../data/images/page-images/about_us_banner.webp'
import { toast } from 'react-toastify';


import { connect } from 'react-redux'
import { setLogIn, setAuth,setUserData } from '../../redux/actions';
import { Link } from 'react-router-dom';

const Login = (props) => {
    // console.log('logjin props', props)
    const sign = require('jwt-encode')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    let secretKey = process.env.REACT_APP_KEY
    async function handleLogin(e) {
        e.preventDefault();
        const token = sign(password, secretKey);

        let body = {
            email: email,
            password: password
        }

        axios.post('/api/login', body)
            .then(async (res) => {
                // console.log('res', res);
                await props?.setAuth(res?.data?.token)
                await props?.setLogIn(true)
                await props?.setUserData(res?.data?.user_data)
                toast.success('Logged in successfully!');
            })
            .catch((err) => {
                console.error('login error: ',err);
                toast.error(err?.response?.data?.error);
            });
    }

    return (
        <div className="container-fluid h-100">
            <div className="row h-100 min-vh-100">
                <div className="col-md-12 d-md-block p-0 background-color">
                    <img className=" h-100 object-fit-cover" src={companyImage} alt="Company image" />
                </div>
                <div className="col-md-12 d-flex flex-column justify-content-center min-vh-100 login-wrapper">
                    <form onSubmit={handleLogin} className="ms-auto me-md-5 col-12 col-md-5 col-lg-3  me-auto rounded-lg login-form p-md-5 p-2 rounded">
                        <h2 className="text-4xl text-white font-weight-bold text-center col">SIGN IN</h2>
                        <div className="form-group text-white py-2">
                            <label htmlFor="username" className='col'>Username</label>
                            <input className="form-control mt-2 p-2" required onChange={(e) => { setEmail(e.target.value) }} type="text" id="username" autoComplete="username" />
                        </div>
                        <div className="form-group text-white py-2">
                            <label htmlFor="password" className='col'>Password</label>
                            <input className="form-control p-2 mt-2" onChange={(e) => { setPassword(e.target.value) }} type="password" id="password" autoComplete="current-password" />
                        </div>
                        <Link to='forgotPass' className='col text-white'>Forgot Password!</Link>
                        <button type="submit" className="col-12 btn btn-primary btn-block mt-3">SIGN IN</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

// export default Login;

const mapStateToProps = (state) => {
    return {
        logged_in: state.logged_in,
        counter: state.counter
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setLogIn: (data) => dispatch(setLogIn(data)),
        setAuth: (data) => dispatch(setAuth(data)),
        setUserData: (data) => dispatch(setUserData(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);