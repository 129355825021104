import React, { useEffect, useState } from 'react'
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';
import "./certifikatat.scss"
// import certifikatat from "../../DB/certifikatat.json"

import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import Translations from '../../../components/translations/translations';
import TranslationModal from '../../translatePagesModal/translationModal';
import AddCertificateModal from './modals/addCertificateModal';
import axios from '../../../axios';
import { toast } from 'react-toastify';




const Certifikatat = (props) => {
    const [isOpen, setIsOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);
    const [modalType, setModalType] = useState(false)
    const [certificates, setCertificates] = useState()
    // console.log('certificates', certificates)
    const handleEditLabelModal = (key) => {
        setIsOpen(true);
        setDataToModal(key)
        setModalType('translation')
    };

    const handleModalClose = () => {
        setIsOpen(false);
        setDataToModal(null)
    };

    function handleModalAdd() {
        setIsOpen(true)
        setModalType('add')
    }

    function handleGetCertificates() {
        axios.get("/api/admin/certificates/all")
            .then((res) => {
                setCertificates(res?.data)
            })
            .catch((err) => {
                // toast.error("Ndodhi nje gabim !")
                // console.log('Ndodhi nje gabim', err)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])
    useEffect(() => {
        handleGetCertificates()
    }, [])

    const images = certificates?.map(el => {
        return ({
            original: `${process.env.REACT_APP_API}/${el?.img_url}`,
            thumbnail: `${process.env.REACT_APP_API}/${el?.img_url}`
        })
    }) || []
    console.log('imagesCertifikata', images)
    return (
        <>
            {isOpen && modalType == 'translation' && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={() => handleModalClose()} handleGetCertificates={() => handleGetCertificates()} />}
            {isOpen && modalType == 'add' && <AddCertificateModal modalType={modalType} isOpen={isOpen} dataToModal={dataToModal} handleModalClose={() => handleModalClose()} handleGetCertificates={() => handleGetCertificates()} />}
            <div className="certifikatat_layout">
                <div className="container">
                    <p className='certifikatat_title' onClick={() => { handleEditLabelModal("Certifikatat!-LABEL") }}>{Translations(props?.langData, props?.selected_lang, 'Certifikatat!-LABEL')}</p>
                    <p className='certifikatat_text' onClick={() => { handleEditLabelModal("Shkrimi-tek-certifikatat-LABEL") }}>{Translations(props?.langData, props?.selected_lang, 'Shkrimi-tek-certifikatat-LABEL')}</p>
                    {/* <img src={`${ process.env.REACT_APP_API } / ${ images[0]?.img_url }`} alt="" /> */}
                    <ImageGallery
                        items={images?.map(el => el)}
                        showPlayButton={false}
                    />
                </div>
            </div>
            <div className="addButton" onClick={handleModalAdd}>
                <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5 " style={{ color: "#0d6efd" }} ></i>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Certifikatat);



// export default Certifikatat