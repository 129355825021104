import React, { useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { setAuth, setLangData, setLogIn, setSelectedLang, sidebarTogel } from './redux/actions';
import { connect } from 'react-redux';
import Homepage from './pages/homepage/homepage';
import Users from './pages/users/users';
import Login from './pages/login/login';
import { ToastContainer, toast } from 'react-toastify';
// import AuthenticatedRoutes from './AuthenticatedRoutes';
import Projects from './pages/projects/projects';
import ProjectStatus from './pages/projects/project_status/status';
import CompanyLogo from './data/images/page-images/Screenshot 2023-09-11.png';
import OurPartners from './pages/ourPartners/ourPartners';
import Labels from './pages/labels/labels';
import Materials from './pages/materials/materials';
import axios from './axios';
import SingleProject from './pages/projects/singleProject/singleProject';
import ProjectTypes from './pages/projects/project_types/project_types';
import SingleProduct from './pages/subPages/singleProduct/singleProduct';
import SubHomepage from './pages/subPages/homepage/homepage';
import SubAboutUs from './pages/subPages/aboutUs/aboutUs';
import SubContactUs from './pages/subPages/contactUs/contactUs';
// import SubOurProjects from './pages/subPages/ourProjects/ourProjects';
import SubManagement from './pages/subPages/management/management';
import SubOurProducts from './pages/subPages/ourProducts/ourProducts';
import SubMirenjohjet from './pages/subPages/mirenjohjet/mirenjohjet';
import SubCertifikatat from './pages/subPages/certifikatat/certifikatat';
import SubKatallogjet from './pages/subPages/katallogjet/katallogjet';
import SubForPublic from './pages/subPages/forPublic/forPublic';
import SubB2B from './pages/subPages/b2b/b2b';
import SubSocialResponsibility from './pages/subPages/pergjegjsiaSociale/pergjegjsiaSociale';
import SubStandardiXhamit from './pages/subPages/standardiXhamit/standardiXhamit';
import SubSingleProduct from './pages/subPages/singleProduct/singleProduct';
import SubPorosia from './pages/subPages/porositTani/porosia';
import ContactUs from './pages/subPages/contactUs/contactUs';
import { Popover, Typography, Button, Stack } from '@mui/material';
import ProfileModal from './profile/profile';
import ForgotPass from './pages/passwordChange/forgotPassword/forgotPass';
import ChangePassMsg from './pages/passwordChange/changePassMsg/changePassMsg';
import TryPassChange from './pages/passwordChange/tryPassChange/tryPassChange';
import OurStaff from './pages/ourStaff/our_staff';
import News from './pages/news/news';

import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';

function App(props) {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log('location', props)
  const isNavOpen = props?.isNavOpen;
  const [rotate, setRotate] = useState(false);
  // { console.log("isNavOpen", isNavOpen) }

  function toggleSubMenu(submenuId) {
    const submenu = document.getElementById(`${submenuId}-submenu`);
    if (submenu) {
      submenu.style.display = submenu.style.display === 'block' ? 'none' : 'block';
    }
  }

  const handleLogOut = () => {
    props.setLogIn(false);
    props.setAuth("");
    navigate('/');
    toast.success('Logged out successfully.');
  };

  const [profileOpen, setProfileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const CustomSubMenu = ({ subPages, navigate, location }) => {
    // console.log("subPages", subPages)
    return (
      <Popover
        className="custom-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'left',
        }}
        style={{ marginLeft: "0.2rem" }}
      >
        <div className="custom-popover-content">
          {subPages.map((elm, cindex) => (
            <a
              key={cindex}
              title={elm.label}
              onClick={() => { setAnchorEl(null); navigate(elm.path) }}
              role='button'
              className={`closed_nav_link ${location.pathname === elm.path && 'ClosedDropDownElementActive'}`}
            >
              <span className="nav_name">{elm.label}</span>
            </a>
          ))}
          <div className="popover-arrow" />
        </div>
      </Popover>
    );
  };

  useEffect(() => {
    axios.get("/api/admin/labels/all")
      .then((res) => { props?.setLangData(res.data) })
      .catch(err => console.log('error getting labels', err))

  }, [])






  const pagesPaths = [
    {
      label: "Homepage",
      path: "/",
      icon: "fas fa-house",
      element: <Homepage />
    },
    {
      label: "Projects",
      path: "/projects",
      icon: "fa-solid fa-diagram-project",
      // <i class="fa-solid fa-diagram-project"></i>
      element: <Projects />
    },
    {
      label: "Project materials",
      path: "/materials",
      icon: "fa-brands fa-mdb",
      element: <Materials />
    },
    {
      label: "Project statuses",
      path: "/project_statuses",
      icon: "fa-solid fa-signal",
      // <i class="fa-solid fa-signal"></i>
      element: <ProjectStatus />
    },
    {
      label: "Project types",
      path: "/project_types",
      icon: "fa-solid fa-font-awesome",
      // <i class="fa-solid fa-font-awesome"></i>
      element: <ProjectTypes />
    },
    {
      label: "Clients",
      path: "/users",
      icon: "fa-solid fa-users",
      element: <Users />
    },
    {
      label: "Labels",
      path: "/labels",
      // <i class="fa-solid fa-globe"></i>
      icon: "fa-solid fa-globe",
      element: <Labels />
    },
    // {
    //   label: "Single Project",
    //   path: "/project/:projectName/:typeId",
    //   icon: "",
    //   element: <SingleProject />
    // },
    {
      label: "Our Partners",
      path: "/ourPartners",
      // <i class="fa-solid fa-handshake"></i>
      icon: "fa-solid fa-handshake",
      element: <OurPartners />
    },
    // {
    //   label: "Our Staff",
    //   path: "/our-staff",
    //   // <i class="fa-solid fa-handshake"></i>
    //   icon: "fa-solid fa-cart-arrow-down",
    //   element: <OurStaff />
    // },
    {
      label: "News",
      path: "/news",
      // <i class="fa-solid fa-handshake"></i>
      icon: "fa-solid fa-newspaper",
      element: <News />
    },
    {
      label: "Client Module Pages",
      icon: "",
      // label: "Pages",
      subPages: [
        {
          label: "Homepage",
          path: "/page/homepage",
          icon: "",
          element: <Labels />
        },
        {
          label: "About Us",
          path: "/page/aboutUs",
          icon: "",
          element: <Labels />
        },
        {
          label: "B2B",
          path: "/page/b2b",
          icon: "",
          element: <Labels />
        },
        {
          label: "Certifikatat",
          path: "/page/certifikatat",
          icon: "",
          element: <Labels />
        },
        {
          label: "Contact Us",
          path: "/page/contactUs",
          icon: "",
          element: <ContactUs />
        },
        {
          label: "For public",
          path: "/page/forPublic",
          icon: "",
          element: <Labels />
        },
        {
          label: "Katallogjet",
          path: "/page/katallogjet",
          icon: "",
          element: <Labels />
        },
        {
          label: "Management",
          path: "/page/management",
          icon: "",
          element: <Labels />
        },
        {
          label: "Mirenjohjet",
          path: "/page/mirenjohjet",
          icon: "",
          element: <Labels />
        },
        {
          label: "Our Products",
          path: "/page/ourProducts",
          icon: "",
          element: <Labels />
        },
        // {
        //   label: "Single Products",
        //   icon: "",
        //   subPages: [

        //   ]
        // },
        {
          label: "PVC Products",
          path: "/page/singleProduct/PVC",
          icon: "",
          element: <SingleProduct />
        },
        {
          label: "Alumin Products",

          path: "/page/singleProduct/Alumin",
          icon: "",
          element: <SingleProduct />
        },
        {
          label: "Glass Products",

          path: "/page/singleProduct/glass",
          icon: "",
          element: <SingleProduct />
        },
        {
          label: "Pergjegjsia Sociale",
          path: "/page/socialResponsebility",
          icon: "",
          element: <Labels />
        },
        {
          label: "Konfiguratori",
          path: "/page/konfiguratori",
          icon: "",
          element: <Labels />
        },

        {
          label: "Standardi Xhamit",
          path: "/page/standardiXhamit",
          icon: "",
          element: <Labels />
        }
      ]
    }
  ]

  const languages = [{ name: 'Shqip', label: " SQ" }, { name: 'English', label: "EN" }, { name: 'German', label: "DE" }];

  const [openLanguageModal, setOpenLanguageModal] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(languages[1]);

  const handleClickOpen = () => {
    setOpenLanguageModal(!openLanguageModal);
  };

  const handleClose = (value) => {
    setOpenLanguageModal(false);
    setSelectedValue(value);
  };



  function SimpleDialog(props) {

    const handleClose = () => {
      setOpenLanguageModal(false)
    };

    console.log(" language modal props", props)
    // console.log(" language modal selectedValue", selectedValue)
    console.log(" language modal open", props?.open)

    const handleListItemClick = (value) => {
      props?.onClose(value);
      setOpenLanguageModal(false)
    };
    return (
      <Dialog onClose={props?.onClose} open={props?.open}>
        <DialogTitle>Set Default Language</DialogTitle>
        <List sx={{ pt: 0 }}>
          {languages.map((language) => (
            <ListItem disableGutters key={language?.name}>
              <ListItemButton onClick={() => handleListItemClick(language)}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    {language?.label} {/* Display first two letters of label */}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={language?.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }

  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    // selectedValue: PropTypes.string.isRequired,
  };

  const isLogedIn = props?.logged_in
  const hasAuth = props?.auth

  return (
    <>
      {
        isLogedIn == false && hasAuth == "" ?
          <Routes>
            < Route path="/" element={< Login />} />
            < Route path="/forgotPass" element={< ForgotPass />} />
            < Route path="/changePassMsg" element={< ChangePassMsg />} />
            < Route path="/reset-password/:token" element={< TryPassChange />} />
            <Route path="*" element={<Login />} />
          </Routes >
          :
          <>
            {profileOpen && <ProfileModal open={profileOpen} setProfileOpen={() => setProfileOpen()} />}
            <div id="body-pd" className={isNavOpen ? 'body-pd' : ''}>
              <header className={`${isNavOpen === true ? 'header-toggle' : "header"} `} id={`${isNavOpen === true ? 'header-toggle' : "modals-open"} `}>
                <div className={`  ${isNavOpen === true ? '' : ""}`} onClick={() => props?.sidebarTogel(!isNavOpen)}>
                  <i className='fas fa-bars' id="header-toggle">  </i>
                </div>
                {/* <div className="row ">
                  <div className="languages_options d-flex ">
                    <p className='languagesButton1 ' >Mirë se erdhe : </p>
                    <p className='languagesButtons selectedLang' >{props?.user_data?.name} {props?.user_data?.surname}  </p>

                  </div>

                  <div className="header_img" onClick={() => setProfileOpen(!profileOpen)}>
                    <img src="https://media.licdn.com/dms/image/C4E03AQESJlpeJhuYcA/profile-displayphoto-shrink_400_400/0/1577053908566?e=1705536000&v=beta&t=a4glRMC4v787sBP77loRTAhm_74Q6k4ANPhfLOJ5l3w" alt="" />
                  </div>
                </div> */}

              </header>
              <div className={`l-navbar ${isNavOpen ? 'show' : ''}`} id="nav-bar">
                <nav className="nav" >
                  <div>
                    <a onClick={() => navigate('/')} role='button' className="nav_logo">
                      {/* <i className='bx bx-layer nav_logo-icon'></i> */}
                      <img src={CompanyLogo} style={{ height: "25px" }} alt="" />
                      <span className="nav_logo-name">{isNavOpen && "Admin Portal"}</span>
                    </a>
                    {/* <div className="languages_options d-flex">
                    <p className='languagesButtons selectedLang'>Gjuha :</p>
                    <p role="button" className={props?.selected_lang == "AL" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("AL") }}>AL</p>
                    <p role="button" className={props?.selected_lang == "EN" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("EN") }}>EN</p>
                    <p role="button" className={props?.selected_lang == "DE" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("DE") }}>DE</p>
                  </div> */}
                    <div className="nav_list">

                      <div className=" nav_link" role='button' onClick={handleClickOpen} >
                        {/* <p className='languagesButtons selectedLang'>{isNavOpen && "Gjuha :"}</p> */}
                        <i class="fa fa-language" aria-hidden="true"></i>
                        <span className='nav_name' >{isNavOpen && "Gjuha platformes"}</span>
                        <SimpleDialog
                          open={openLanguageModal}
                          onClose={handleClose}
                        />
                      </div>
                      {pagesPaths?.map((el, index) => {
                        // console.log('location.pathname ', location.pathname.split('/')[1]);
                        if (el?.subPages && isNavOpen) {
                          return (
                            <div key={index} role='button' title={el?.label} className={`nav_link ${location.pathname.split('/')[1] == "page" && isNavOpen ? 'DropDownActive' : location.pathname.split('/')[1] == "page" && "active"}`}>
                              <div onClick={() => isNavOpen && (toggleSubMenu('projects'), setRotate(!rotate))} className="d-flex align-items-center">
                                <i className='fa-solid fa-table-columns' ></i>
                                <span className="nav_name ">{isNavOpen && el?.label}</span>
                                <div className={rotate ? "shouldRotate" : 'shouldClose'}>
                                  <i className={`fa-solid fa-chevron-right submenu-icon `}></i>

                                </div>
                              </div>
                              <div className="ms-2 submenu" id="projects-submenu">
                                {el?.subPages?.map((elm, cindex) => {
                                  // { console.log('subdiqka', elm) }
                                  return (
                                    <a key={cindex} title={elm?.label} onClick={() => navigate(elm?.path)} role='button' className={`nav_link ${location.pathname == elm?.path && isNavOpen && 'DropDownElementActive'}`}>
                                      <span className="nav_name">{isNavOpen && elm?.label}</span>
                                    </a>
                                  )
                                })}
                              </div>
                            </div>
                          );
                        } else if (el?.subPages && !isNavOpen) {
                          return (
                            <div
                              key={index}
                              role="button"
                              title={el.label}
                              onMouseEnter={handlePopoverOpen} // Open popover on mouse enter
                              onMouseLeave={handlePopoverClose}
                              className={`nav_link ${location.pathname.split('/')[1] === 'page' && isNavOpen ? 'DropDownActive' : location.pathname.split('/')[1] === 'page' && 'active'}`}
                            >
                              <div className="d-flex align-items-center">
                                <i className="fa-solid fa-table-columns"></i>
                                <span className="nav_name">{isNavOpen && el.label}</span>
                              </div>
                              <div className="ms-2 submenu" id="projects-submenu">
                                <CustomSubMenu subPages={el.subPages} navigate={navigate} location={location} />
                              </div>
                              {/* <Popover
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={handlePopoverClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <div className="ms-2 submenu" id="projects-submenu">
                                {el.subPages.map((elm, cindex) => (
                                  <a
                                    key={cindex}
                                    title={elm.label}
                                    onClick={() => navigate(elm.path)}
                                    role="button"
                                    className={`nav_link ${location.pathname === elm.path  && 'DropDownElementActive'}`}
                                  >
                                    <span className="nav_name">{ elm.label}</span>
                                  </a>
                                ))}
                              </div>
                            </Popover> */}
                            </div>
                          );

                        } else {
                          return (
                            <a key={index} title={el?.label} onClick={() => navigate(`${el?.path}`)} role='button' className={`nav_link ${location.pathname == `${el?.path}` && 'active'}`}>
                              <i className={el?.icon}></i>
                              <span className="nav_name">{isNavOpen && el?.label}</span>
                            </a>
                          );
                        }
                      })}

                    </div>
                  </div>
                  <a onClick={() => handleLogOut()} role='button' className="nav_link">
                    <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                    <span className="nav_name">{isNavOpen && "SignOut"}</span>
                  </a>
                </nav>
              </div>
              <div className={`${isNavOpen ? 'bg-light content-container-toggle' : ' bg-light content-container'}`} style={{ overflow: "auto" }}>
                <Routes>
                  <Route path="/" element={<Homepage />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/materials" element={<Materials />} />
                  <Route path="/project_statuses" element={<ProjectStatus />} />
                  <Route path="/project_types" element={<ProjectTypes />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/labels" element={<Labels />} />
                  <Route path="/ourPartners" element={<OurPartners />} />
                  {/* <Route path="/our-staff" element={<OurStaff />} /> */}
                  <Route path="/news" element={<News />} />
                  <Route path="/project/:projectName/:typeId" element={<SingleProject />} />
                  <Route path="/page/homepage" element={<SubHomepage />} />
                  <Route path="/page/aboutUs" element={<SubAboutUs />} />
                  <Route path="/page/B2B" element={<SubB2B />} />
                  <Route path="/page/certifikatat" element={<SubCertifikatat />} />
                  <Route path="/page/contactUs" element={<SubContactUs />} />
                  <Route path="/page/forPublic" element={<SubForPublic />} />
                  <Route path="/page/katallogjet" element={<SubKatallogjet />} />
                  <Route path="/page/management" element={<SubManagement />} />
                  <Route path="/page/mirenjohjet" element={<SubMirenjohjet />} />
                  <Route path="/page/ourProducts" element={<SubOurProducts />} />
                  <Route path="/page/singleProduct/:type" element={<SubSingleProduct />} />
                  <Route path="/page/socialResponsebility" element={<SubSocialResponsibility />} />
                  <Route path="/page/konfiguratori" element={<SubPorosia />} />
                  <Route path="/page/standardiXhamit" element={<SubStandardiXhamit />} />
                  {/* <Route path="/page/projects" element={<SubOurProjects />} /> */}
                </Routes>
              </div>
            </div>
          </>
      }
    </>

  );
}

const mapStateToProps = (state) => {
  return {
    logged_in: state.logged_in,
    auth: state.auth,
    user_data: state.user_data,
    isNavOpen: state.isNavOpen,
    selected_lang: state.selected_lang
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogIn: (data) => dispatch(setLogIn(data)),
    setAuth: (data) => dispatch(setAuth(data)),
    setSelectedLang: (data) => dispatch(setSelectedLang(data)),
    setLangData: (data) => dispatch(setLangData(data)),
    sidebarTogel: (data) => dispatch(sidebarTogel(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
