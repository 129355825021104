import React, { useEffect, useState } from 'react'
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';
import "./mirenjohjet.scss"
// import mirenjohjetDb from "../../DB/mirenjohjet.json"
import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import Translations from '../../../components/translations/translations';
import TranslationModal from '../../translatePagesModal/translationModal';

const Mirenjohjet = (props) => {
    const [isOpen, setOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);

    const handleEditLabelModal = (key) => {
        setOpen(true);
        setDataToModal(key)
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])
    let mirenjohjetDb;
    const images = mirenjohjetDb || []
    // console.log('imagesMirenjohje', images)

    return (
        <>
            {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}
            <div className="mirenjohjet_layout">
                <div className="container">
                    <p className='mirenjohjet_title' onClick={() => { handleEditLabelModal("Mirenjohjet-tona-Label") }}>{Translations(props?.langData, props?.selected_lang, 'Mirenjohjet-tona-Label')}</p>
                    <p className='mirenjohjet_text' onClick={() => { handleEditLabelModal("Mirenjohjet-tona-description-Label") }}>{Translations(props?.langData, props?.selected_lang, 'Mirenjohjet-tona-description-Label')}</p>
                    <ImageGallery
                        items={images}
                        showPlayButton={false}
                    />
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mirenjohjet);


// export default Mirenjohjet