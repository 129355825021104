import axios from '../../../axios'
import React, { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@mui/material'; // Import the necessary components from @mui/material
import { toast } from 'react-toastify';


const AddMaterialsModal = (props) => {
    // console.log('modal props blyat', props)
    const [material_al, setMaterialAl] = useState(props?.dataToModal?.material_al || '');
    const [material_en, setMaterialEn] = useState(props?.dataToModal?.material_en || '');
    const [material_de, setMaterialDe] = useState(props?.dataToModal?.material_de || '');


    function handleMaterialPost() {
        let body = { material_al, material_en, material_de }
        axios.post("/api/admin/project_materials", body)
            .then((res) => {
                props?.handleMaterialGet();
                toast.success('Materiali u shtua me sukses!');
                props?.handleModalClose();
                // console.log('u postu materiali', res)
            })
            .catch((err) => { console.log('u postu materiali', err) })
    }
    function handleMaterialUpdate() {
        let body = { id: props?.dataToModal?.id, material_al, material_en, material_de }
        axios.put("/api/admin/project_materials", body)
            .then((res) => {
                props?.handleMaterialGet();
                toast.success('Materiali u editua me sukses!');
                props?.handleModalClose();
                // console.log('u editu materiali', res)
            })
            .catch((err) => { console.log('u postu materiali', err) })
    }
    function handleDelete(sid) {
        axios.delete(`/api/admin/project_materials/${sid}`,)
            .then((res) => {
                toast.success("Statusi u fshi me sukses");
                props?.handleMaterialGet();
                props?.handleModalClose();
                // console.log('u fshi materiali', res)
            })
            .catch((err) => { console.log('u postu materiali', err) })
    }
    return (
        <>{props?.modalType == "add" ?
            <Dialog open={props?.isOpen} onClose={() => { props?.handleModalClose() }}>
                <DialogTitle>Insert Data</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            label="Material AL"
                            type="text"
                            value={material_al}
                            onChange={(e) => setMaterialAl(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                    <div>
                        <TextField
                            label="Material EN"
                            type="text"
                            value={material_en}
                            onChange={(e) => setMaterialEn(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                    <div>
                        <TextField
                            label="Material DE"
                            type="text"
                            value={material_de}
                            onChange={(e) => setMaterialDe(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props?.handleModalClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleMaterialPost} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            : props?.modalType == "edit" ?
                <Dialog open={props?.isOpen} onClose={props?.handleModalClose}>
                    <DialogTitle>Edit Data</DialogTitle>
                    <DialogContent>
                        <div>
                            <TextField
                                label="Material AL"
                                type="text"
                                value={material_al}
                                onChange={(e) => setMaterialAl(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div>
                            <TextField
                                label="Material EN"
                                type="text"
                                value={material_en}
                                onChange={(e) => setMaterialEn(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div>
                            <TextField
                                label="Material DE"
                                type="text"
                                value={material_de}
                                onChange={(e) => setMaterialDe(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props?.handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleMaterialUpdate} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                <Dialog open={props?.isOpen}>
                    <DialogTitle>Confirm material Deletion</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" >
                            Are you sure you want to delete :<br /> "{props?.dataToModal?.material_al}"?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props?.handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleDelete(props?.dataToModal?.id)} color="primary">
                            Yes, Delete
                        </Button>
                    </DialogActions>
                </Dialog>
        }

        </>
    )
}

export default AddMaterialsModal