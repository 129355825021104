import React, { useEffect, useState } from 'react'
import "./katallogjet.scss"

// import PVCpdfShqip from "../../katallogjet/Website-PDF-Shqip/Katallogu_Produktet e PVC_FINAL_RGB.pdf"
// import PVCpdfAnglisht from "../../katallogjet/Website-PDF-English/CATALOGUE OF PVC PRODUCTS_FINAL_RGB.pdf"
// import PVCpdfGjermanisht from "../../katallogjet/Website-PDF-German/Katallogu German_PVC.pdf"

// import GlassPDFShqip from "../../katallogjet/Website-PDF-Shqip/Katallogu_Produktet nga XHAMI_FINAL_RGB.pdf"
// import GlassPDFAnglisht from "../../katallogjet/Website-PDF-English/CATALOGUE OF GLASS_FINAL_RGB.pdf"
// import GlassPDFGjermanisht from "../../katallogjet/Website-PDF-German/Katallogu German_GLASS.pdf"

// import AluminiumPDFShqip from "../../katallogjet/Website-PDF-Shqip/Katallogu_Produktet nga ALUMINI_2023 FINAL_RGB.pdf"
// import AluminiumPDFAnglisht from "../../katallogjet/Website-PDF-English/CATALOGUE OF ALUMINIUM PRODUCTS_FINAL_RGB.pdf"
// import AluminiumPDFGjermanisht from "../../katallogjet/Website-PDF-German/Katallogu German_ALUMINUM.pdf"

import pvc_img from "../../../assets/images/katalogjet/Katalogu_pvc.png"
import glass_img from "../../../assets/images/katalogjet/Katalogu_Xhami.png"
import alumin_img from "../../../assets/images/katalogjet/Katalogu_alumin.png"


import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import Translations from '../../../components/translations/translations';
import TranslationModal from '../../translatePagesModal/translationModal'


const Katallogjet = (props) => {
    const [isOpen, setOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);

    const handleEditLabelModal = (key) => {
        setOpen(true);
        setDataToModal(key)
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    return (
        <>
            {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}
            <div className="katallogjet_layout">
                <div className="container">
                    <div className="row justify-cards">
                        <div className="card mb-5 single_katallog" style={{ maxWidth: "18rem" }}>
                            <img className="card-img-top " style={{ maxWidth: "17rem" }} src={pvc_img} alt="Card image cap" />
                            <div className="card-body">
                                <p className="Card-Title" onClick={() => { handleEditLabelModal("Katallogu-PVC-Label") }}>{Translations(props?.langData, props?.selected_lang, 'Katallogu-PVC-Label')}</p>
                                <div className="d-flex justify-content-center">
                                    <a className='m-2' >Shqip</a>
                                    <a className='m-2' >English</a>
                                    <a className='m-2' >German</a>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-5 single_katallog" style={{ maxWidth: "18rem" }}>
                            <img className="card-img-top " style={{ maxWidth: "17rem" }} src={glass_img} alt="Card image cap" />
                            <div className="card-body">
                                <p className="Card-Title" onClick={() => { handleEditLabelModal("Katallogu-Xhamit-Label") }}>{Translations(props?.langData, props?.selected_lang, 'Katallogu-Xhamit-Label')}</p>
                                <div className="d-flex justify-content-center">
                                    <a className='m-2' >Shqip</a>
                                    <a className='m-2' >English</a>
                                    <a className='m-2' >German</a>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-5 single_katallog" style={{ maxWidth: "18rem" }}>
                            <img className="card-img-top " style={{ maxWidth: "17rem" }} src={alumin_img} alt="Card image cap" />
                            <div className="card-body">
                                <p className="Card-Title" onClick={() => { handleEditLabelModal("Katallogu-Aluminit-Label") }}>{Translations(props?.langData, props?.selected_lang, 'Katallogu-Aluminit-Label')}</p>
                                <div className="d-flex justify-content-center">
                                    <a className='m-2' >Shqip</a>
                                    <a className='m-2' >English</a>
                                    <a className='m-2' >German</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}


const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Katallogjet);


// export default Katallogjet