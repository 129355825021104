import React, { useState } from 'react';
import './homepage.scss'; // Import your custom CSS for styling

const Homepage = () => {
  const [iframeBorderColor, setIframeBorderColor] = useState('#007bff'); // Initial border color

  // Function to change the iframe border color
  const changeBorderColor = () => {
    // Generate a random color for the border
    const randomColor = '#' + (Math.random().toString(16) + '000000').substring(2, 8);
    setIframeBorderColor(randomColor);
  };

  return (
    <div className="homepage-container">
      <h1 className="welcome-text">Welcome to the Admin Portal</h1>
      <div className="iframe-container">
        <h2 className="preview-header">Live Preview of client module:</h2>
        <iframe
       
          src="https://www.m-technologie.com/"
          title="Web Preview"
          width="100%"
          // frameBorder="0"
          className="preview-iframe"
          style={{ borderColor: iframeBorderColor }} // Set the border color dynamically
          onMouseEnter={changeBorderColor} // Change border color on mouse enter
        ></iframe>
      </div>
    </div>
  );
};

export default Homepage;
