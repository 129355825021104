import axios from '../../axios'
import React, { useEffect, useState } from 'react'

const Users = () => {
    const [allCostumers, setAllCostumers] = useState()
    function handleGetAllCostumers() {
        axios.get("/api/admin/costumers/all")
            .then((res) => {
                // console.log('res.data', res)
                setAllCostumers(res.data)
            })
            .catch((err) =>
                console.log("error getting labels", err))
    }


    useEffect(() => {
        handleGetAllCostumers()
    }, [])
    return (
        <table className=" col-12 table table table-hover">
            <thead>
                <tr>
                    <th className="col">Id</th>
                    <th className="col">name_surname</th>
                    <th className="col">address</th>
                    <th className="col">city</th>
                    <th className="col">email</th>
                    <th className="col">phone_number</th>
                    <th className="col">email_sent</th>
                </tr>
            </thead>
            <tbody>

                {allCostumers?.map((el, index) =>
                    <tr key={index}>
                        <th scope="row">{el?.uuid}</th>
                        <td className='col-'>{el?.name_surname}</td>
                        <td className='col-'>{el?.address}</td>
                        <td className='col-'>{el?.city}</td>
                        <td className='col-'>{el?.email}</td>
                        <td className='col-'>{el?.phone_number}</td>
                        <td className='col-'>{el?.email_sent}</td>
                        {/* <td className='col-2'><i className='fas fa-trash' /></td> */}
                    </tr>

                )}
            </tbody>
        </table>
    )
}

export default Users