import React, { useState, } from 'react';
import "./singleProject.scss";
import { Link, useLocation, useParams } from 'react-router-dom';
// import { Carousel } from '3d-react-carousal';
import Slider from "react-slick";
import { useEffect } from 'react';
import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';

import Translations from '../../../components/translations/translations';
import axios from '../../../axios';
import { toast } from 'react-toastify';
import EditProjectsContentModal from '../modals/editProjectContent';



const SingleProject = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [dataToModal, setDataToModal] = useState(null);

    function handleModalClose() {
        setIsOpen(false)
        setDataToModal(null)
    }
    
    function handleEditProjectModal() {
        setIsOpen(true)
        setModalType("edit")
        setDataToModal(thisPageData)
    }

    let { projectName, typeId } = useParams()
    // console.log("params", projectName, typeId)
    //   var backgroundPosition;
    const [thisPageData, setthisPageData] = useState()
    const [statuses, setStatuses] = useState()
    function getSingleProject() {
        axios.get(`/api/admin/projects/single/${projectName}/${typeId}`)
            .then((res) => {
                // console.log(res.data)
                setthisPageData(res.data)
            })
            .catch((err) => {
                toast.error('Ndodhi nje error, ju lutem provoni me vone!')
                console.log('error fetching page data', err)
            })
        // .finaly()
    }

    useEffect(() => {
        getSingleProject()
        window.scrollTo(0, 0)
    }, [window.location.reload])

    const [imageIndex, setImageIndex] = useState(1)

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }




    // Slick Slider Settings
    var slickSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) =>
            setImageIndex(current + 1),
        afterChange: current => setImageIndex(current + 1)

    };


    const style = {
        backgroundImage: `url(${process.env.REACT_APP_API}/${thisPageData?.bg_img})`
    }

    const style1 = {
        backgroundPosition: "4%",
        backgroundImage: `url(${process.env.REACT_APP_API}/${thisPageData?.bg_img})`
    }
    const pathi = useLocation();
    const test = pathi.pathname == "/project/prishtinaMall/qenderTregtare" ? style1 : style



    return (

        <>
            {isOpen && <EditProjectsContentModal isOpen={isOpen} modalType={modalType} dataToModal={dataToModal} handleModalClose={handleModalClose} />}
            <div className='SinglePage'>
                <div className="col-12 fotografiaProjektit img-fluid"
                    style={test}
                >

                    <img className="col-12 fotografiaProjektit img-fluid" src={`${process.env.REACT_APP_API}/${thisPageData?.bg_img}`} style={{ objectFit: 'cover' }} alt={thisPageData?.obiject_name_al} />
                    <div className="background_text_shadow">
                        <h5 className="emriProjektit">
                            {props?.selected_lang == "AL" ? thisPageData?.obiject_name_al
                                : props?.selected_lang == "EN" ? thisPageData?.obiject_name_en
                                    : thisPageData?.obiject_name_de
                            }
                        </h5>
                        <p className="lokacioniProjektit mb-3">
                            <i className="fas fa-map-marker-alt " aria-hidden="true" /> &nbsp;
                            {props?.selected_lang == "AL" ? thisPageData?.location_al
                                : props?.selected_lang == "EN" ? thisPageData?.location_en
                                    : thisPageData?.location_de
                            }
                        </p>
                    </div>
                </div>
                <div className="pershkrimiProjektit">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className=" col-md-3">
                                <div className="vija_ndarse_statuseve">
                                    <div className=" statuset">
                                        <p className='emriPershkrimit'>{Translations(props?.langData, props?.selected_lang, 'LLOJIIBIZNESITlabel')}</p>
                                        <p className='statusiNgaJSON'>
                                            {/* {Translations(props?.langData, props?.selected_lang,)} */}
                                            {props?.selected_lang == "AL" ? thisPageData?.businessType?.type_al
                                                : props?.selected_lang == "EN" ? thisPageData?.businessType?.type_en
                                                    : thisPageData?.businessType?.type_de
                                            }

                                        </p>
                                    </div>
                                    <div className="vija_ndarese" />
                                </div>
                            </div>
                            <div className=" col-md-3">
                                <div className="vija_ndarse_statuseve">
                                    <div className=" statuset">
                                        <p className='emriPershkrimit'>
                                            {Translations(props?.langData, props?.selected_lang,
                                                'OBJECTLabel')}
                                        </p>
                                        <p className='statusiNgaJSON'>
                                            {props?.selected_lang == "AL" ? thisPageData?.obiject_name_al
                                                : props?.selected_lang == "EN" ? thisPageData?.obiject_name_en
                                                    : thisPageData?.obiject_name_de
                                            }
                                        </p>
                                    </div>
                                    <div className="vija_ndarese" />
                                </div>
                            </div>
                            <div className=" col-md-3">
                                <div className="vija_ndarse_statuseve">
                                    <div className=" statuset">
                                        <p className='emriPershkrimit'>{Translations(props?.langData, props?.selected_lang, 'STATUSlabel')}</p>
                                        <p className='statusiNgaJSON'>
                                            {props?.selected_lang == "AL" ? thisPageData?.project_status?.status_al
                                                : props?.selected_lang == "EN" ? thisPageData?.project_status?.status_en
                                                    : thisPageData?.project_status?.status_de
                                            }
                                        </p>
                                    </div>
                                    <div className="vija_ndarese" />
                                </div>
                            </div>
                            {/* <div className=" col-md-3">
                            <div className="vija_ndarse_statuseve">
                                <div className=" statuset">
                                    <p className='emriPershkrimit'>ARCHITECT</p>
                                    <p className='statusiNgaJSON'>{thisPageData?.architect}</p>
                                </div>
                                <div className="vija_ndarese" />
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>

                <div className="kontentiProjektit">
                    <div className="container">
                        <div className="rreshtimi">
                            <img className="col-md-5 col-12 imazhi"
                                src={`${process.env.REACT_APP_API}/${thisPageData?.project_content?.content_img}`}

                            />
                            <div className="col-md-5 col-12 pershkrimiImazhit">
                                <div className="detajetEprojektit">
                                    <h1 className='titulli'>
                                        {Translations(props?.langData, props?.selected_lang, 'DetajetEProjektitPerkthim')}</h1>
                                    <p className='tekstiSinglepage'
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                props?.selected_lang == "AL" ? thisPageData?.project_content?.project_details_al
                                                    : props?.selected_lang == "EN" ? thisPageData?.project_content?.project_details_en
                                                        : thisPageData?.project_content?.project_details_de

                                        }}
                                    >

                                    </p>
                                </div>
                                <div className="materialet">{console.log("first123", thisPageData?.project_content?.content_materials?.map((el) => { return { material_id: el?.id, project_content_id: thisPageData?.project_content?.id } }))}
                                    <h1 className='titulli' >{Translations(props?.langData, props?.selected_lang, 'MaterialetLabel')}</h1>
                                    <ul className='listimiUL'>
                                        {thisPageData?.project_content?.content_materials?.map((el) => {
                                            return <li>
                                                {props?.selected_lang == "AL" ? el?.material_al
                                                    : props?.selected_lang == "EN" ? el?.material_en
                                                        : el?.material_de
                                                }
                                            </li>
                                        })

                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="galleria pt-5 pb-5  ">

                    <div className="container  mb-3">
                        <div className="row">
                            <p className='galleria_title col-6'>{Translations(props?.langData, props?.selected_lang, 'GaleriaLabel')}</p>
                            {/* {console.log('thisPageData?.galleria', thisPageData?.galleria)} */}
                            <p className='galleria_image_number col-6'>{imageIndex}/{thisPageData?.project_gallery?.length}</p>
                        </div>
                        <Slider {...slickSettings}>
                            {thisPageData?.project_gallery?.map((el) => {
                                console.log('elementi galle', el)
                                return (
                                    <>
                                        <img src={`${process.env.REACT_APP_API}/${el?.img_url}`}
                                            alt={el?.alt}
                                            className="single_project_slider"
                                        />
                                    </>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </div >
            <div className="addButton" onClick={() => handleEditProjectModal()}>
                {/* <i class="fa-solid fa-pen-to-square"></i> */}
                {/* role="button" onClick={() => handleEditProjectsModal(thisPageData?.project_content?.content_materials?.map((el) => { return { material_id: el?.id, project_content_id: thisPageData?.project_content?.id } }), thisPageData?.project_content?.id)} */}
                <i type={"button"} className="fa-solid fa-pen-to-square fa-2xl pt-5 " style={{ color: "#0d6efd" }} ></i>
            </div>
        </>
    )

}
const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: () => dispatch(setLangData()),
        // setSelectedLang: (lang) => dispatch(setSelectedLang(lang)),
        setProjectsData: (projects) => dispatch(setSelectedLang(projects))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProject);