import './homepage.scss';
import React, { useEffect, useState } from 'react';
import PVCimg from "../../../assets/images/Produkte-PVC.png"
import AluminProdukt from "../../../assets/images/Produktet-e-Aluminit.jpg"
import SpiderGlassIMG from "../../../assets/images/Spider-Glass.png"
import ZgjedhShqip from "../../../assets/images/PVC-Shqip.png"
import ZgjedhEN from "../../../assets/images/PVC-English.png"
import ZgjedhDE from "../../../assets/images/PVC-German.png"
import imagePrishtina from "../../../assets/images/prishtinaBg.png"
import imagePrishtinaMobile from "../../../assets/images/prishtinaBg_mobile.png"
import Translations from '../../../components/translations/translations';
import Slider from "react-slick";
import { Link } from 'react-router-dom';


// images of the slider with z-index !

import SliderImages from "../../../DB/sliderImages.json"

// import { setLangData, setSelectedLang } from "../../redux/actions";
import { connect } from 'react-redux';

import SwiperCore, { Navigation, Pagination, EffectCards } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'; // Import Swiper styles
import 'swiper/css/navigation'; // Import Swiper Navigation styles
import 'swiper/css/pagination'; // Import Swiper Pagination styles
import 'swiper/css/effect-cards'; // Import Swiper Effect Cards styles
import TranslationModal from '../../translatePagesModal/translationModal';


SwiperCore.use([Navigation, Pagination]);

// import required modules
const Homepage = (props) => {
  // console.log('hoempoa', props)
  // let slicedGalley = galleryData.slice(0, 6);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(null);
  const [dataToModal, setDataToModal] = useState(null);
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {

    window.onload = function () {
      // All static resources, including images, have finished loading
      // Disable your loader here
    };
  }, [])

  const handleChange = (event) => {
    const index = parseInt(event.target.value);
    setActiveIndex(index);
    swiper.slideTo(index, 300);
    // const { value } = event.target.value;
    // console.log('event.target', event.target.value)
    // // setActiveIndex(value);
    // console.log('swiper',params.onSwiper(value))
    // // params?.onSwiper = setActiveIndex(value)
    // params.onSwiper(value)
    // swiper.slideTo(value, 300, false);
  };

  const params = {
    speed: 500,
    effect: "cards",
    modules: [EffectCards],
    className: "mySwiper",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setActiveIndex(e.activeIndex),
    // onSwiper: (swiper) => setActiveIndex(swiper),
  };
  // Slick Slider Settings
  var slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  var firstSlickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 800,
    cssEase: "linear"
  };
  const style1 = {
    backgroundPosition: "4%",
  }

  const handleEditLabelModal = (key) => {
    setOpen(true);
    setDataToModal(key)
  };

  const handleModalClose = () => {
    setOpen(false);
    setDataToModal(null)
  };

  return (
    <>
      {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}

      <div className="homepage_layout">
      
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////// SECOND PART OF PAGE ///////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <div className="slick_mobile_slider ">
          <h1 className=' col-10 mx-auto prorduketTitle pt-3 pb-3' role='button' onClick={() => { handleEditLabelModal("produkteTeKualitetitTeLarte") }}>{Translations(props?.langData, props?.selected_lang, 'produkteTeKualitetitTeLarte')}</h1>
          <Slider {...slickSettings}>
            <div>
              <Link to={"/products/alumin"} style={{ textDecoration: 'none' }} className="card col-md-3 col-11 mx-auto border-0">
                <img className="card-img-top " alt="Alumni Project" src={AluminProdukt} />
                <div className="description mt-4 ">
                  <h5 className=" name-hide cardTitle" role='button' onClick={() => { handleEditLabelModal("produkteNgaAlumini") }}>{Translations(props?.langData, props?.selected_lang, 'produkteNgaAlumini')}</h5>
                  <p className="card-text " role='button' onClick={() => { handleEditLabelModal("pershkrimiProdukteveTeAluminit") }}>{Translations(props?.langData, props?.selected_lang, 'pershkrimiProdukteveTeAluminit')}</p>
                </div>
              </Link>
            </div>
            <div>
              <Link to={"/products/PVC"} style={{ textDecoration: 'none' }} className="card col-md-3 col-11 mx-auto border-0">
                <img className="card-img-top" src={PVCimg} />
                <div className="description mt-4">
                  <h5 className=" name-hide cardTitle">PVC</h5>
                  <p className="card-text " role='button' onClick={() => { handleEditLabelModal("tekstiProduktevePVC") }}>{Translations(props?.langData, props?.selected_lang, 'tekstiProduktevePVC')}</p>
                </div>
              </Link>
            </div>
            <div>
              <Link to={"/products/glass"} style={{ textDecoration: 'none' }} className="card col-md-3 col-11 mx-auto border-0">
                <img className="card-img-top " src={SpiderGlassIMG} />
                <div className="description mt-4">
                  <h5 className=" name-hide cardTitle" role='button' onClick={() => { handleEditLabelModal("produkteNgaXhami") }}>{Translations(props?.langData, props?.selected_lang, 'produkteNgaXhami')} </h5>
                  <p className="card-text " role='button' onClick={() => { handleEditLabelModal("tekstiProdukteveNgaXhami") }}>{Translations(props?.langData, props?.selected_lang, 'tekstiProdukteveNgaXhami')}</p>
                </div>
              </Link>
            </div>
          </Slider>
        </div>

        <div className="container">
          <div className="materialet_punuese_container">
            <div className="col-md-12">
              <div className="materialet_punuese">
                <h1 className='porduketTitle pt-5 pb-5' role='button' onClick={() => { handleEditLabelModal("produkteTeKualitetitTeLarte") }}>{Translations(props?.langData, props?.selected_lang, 'produkteTeKualitetitTeLarte')}</h1>
                <div className="aranzhoCardat pb-5 row " >
                  <div className="card col-md-3 col-12 ">
                    <img className="card-img-top " alt="Alumni Project" src={AluminProdukt} />
                    <div className="description mt-4">
                      <h5 className=" name-hide cardTitle" role='button' onClick={() => { handleEditLabelModal("produkteNgaAlumini") }}>{Translations(props?.langData, props?.selected_lang, 'produkteNgaAlumini')}</h5>
                      <p className="card-text " role='button' onClick={() => { handleEditLabelModal("pershkrimiProdukteveTeAluminit") }}>{Translations(props?.langData, props?.selected_lang, 'pershkrimiProdukteveTeAluminit')}</p>
                    </div>
                  </div>

                  <div className="card col-md-3 col-12">
                    <img className="card-img-top" src={PVCimg} />
                    <div className="description mt-4">
                      <h5 className=" name-hide cardTitle">PVC</h5>
                      <p className="card-text " role='button' onClick={() => { handleEditLabelModal("tekstiProduktevePVC") }}>{Translations(props?.langData, props?.selected_lang, 'tekstiProduktevePVC')}</p>
                    </div>
                  </div>

                  <div className="card col-md-3 col-12 ">
                    <img className="card-img-top " src={SpiderGlassIMG} />
                    <div className="description mt-4">
                      <h5 className=" name-hide cardTitle" role='button' onClick={() => { handleEditLabelModal("produkteNgaXhami") }}>{Translations(props?.langData, props?.selected_lang, 'produkteNgaXhami')} </h5>
                      <p className="card-text " role='button' onClick={() => { handleEditLabelModal("tekstiProdukteveNgaXhami") }}>{Translations(props?.langData, props?.selected_lang, 'tekstiProdukteveNgaXhami')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}


        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////// MATERIALI PUNUES ///////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}
        <div className=" descriptionOfWork " >
          <div className="row m-0">
            <div className="col-md-6 col-12 image_background ">
              {props?.selected_lang == "AL" ?
                <img data-aos="fade-right" data-aos-duration="1000" className='pershkrimiXhamitPVC img-fluid col-6 col-12' src={ZgjedhShqip} alt="Glass Description" />
                : props?.selected_lang == "EN" ?
                  <img data-aos="fade-right" data-aos-duration="1000" className='pershkrimiXhamitPVC img-fluid col-6 col-12 ' src={ZgjedhEN} alt="Glass Description" />
                  : props?.selected_lang == "DE" ?
                    <img data-aos="fade-right" data-aos-duration="1000" className='pershkrimiXhamitPVC img-fluid col-6 col-12 ' src={ZgjedhDE} alt="Glass Description" />
                    :
                    <img data-aos="fade-right" data-aos-duration="1000" className='pershkrimiXhamitPVC img-fluid col-6 col-12' src={ZgjedhShqip} alt="Glass Description" />
              }
            </div>
            <div className="col-md-6 image_description_background" >
              <div className="container mt-5" data-aos="fade-left" data-aos-duration="1000">
                <p className='profile_description_title mt-4' role='button' onClick={() => { handleEditLabelModal("ProjektoDritarënTëndeNëTreHapa") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ProjektoDritarënTëndeNëTreHapa')}` }}></p>
                <p className='profile_description_pharagraph col-md-7' role='button' onClick={() => { handleEditLabelModal("TekstiProjektimiDritares") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'TekstiProjektimiDritares')}` }}></p>
                <p className='profile_project_button' role='button' onClick={() => { handleEditLabelModal("ProjektoTekstiKrijoImazhin") }}>  {Translations(props?.langData, props?.selected_lang, 'ProjektoTekstiKrijoImazhin')} </p>
              </div>
            </div>
          </div>
        </div>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}




        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////// SLIDERI WITH Z-INDEX //////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <div className="col-12 SliderDiv">
          <div className="container">
            <div className="row">
              <div className="col-md-6" data-aos="fade-right" data-aos-duration="1000">
                <p className='titulliSlider col-md-md-8' role='button' onClick={() => { handleEditLabelModal("PlanifikimiDheRealizimiDeriNfund") }}>{Translations(props?.langData, props?.selected_lang, 'PlanifikimiDheRealizimiDeriNfund')}</p>
                <p className='pershkrimiSlider col-md-10' role='button' onClick={() => { handleEditLabelModal("PershkrimiTekSlideriKompleks") }}>{Translations(props?.langData, props?.selected_lang, 'PershkrimiTekSlideriKompleks')}
                </p>
              </div>
              <div className="col-md-6 parent_complex_slider_div">
                <div className="image-wrapper ">
                  <div className="">
                    <Swiper {...params}>
                      {SliderImages?.map((el, index) => (
                        <SwiperSlide key={index}>
                          <div key={index}>
                            {/* <p>{index}</p> */}
                            <img className="complexSliderIMG img-fluid col-md-12" src={el?.img} alt={`Slide ${index}`} />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <div className="selector_style">
                  <input
                    type="range"
                    min="0"
                    max="8"
                    value={activeIndex}
                    onChange={handleChange}
                  />
                  < p className="drag_and_discover_text  col-md-4 m-0" role='button' onClick={() => { handleEditLabelModal("dragAndDiscoverSliderTekst") }}>{Translations(props?.langData, props?.selected_lang, 'dragAndDiscoverSliderTekst')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////// IMAZHI ME TEKST /////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <div className="col-md-12 imazhiPrishtineDiv">
          <img className='imazhiPrishtines' src={imagePrishtina} alt="" />
          <img className='imazhiPrishtinesMobile img-fluid' src={imagePrishtinaMobile} alt="" />
          <div className="pershkrimiPrishtine col-md-4" data-aos="fade-right" data-aos-duration="1000">
            <p className='TitulliPrishtine col-md-10' role='button' onClick={() => { handleEditLabelModal("benifitetEdritareveTeKualitetitTeLarte") }}>{Translations(props?.langData, props?.selected_lang, 'benifitetEdritareveTeKualitetitTeLarte')}</p>
            <hr className='vizaNdarese col-md-10' />
            <ul>
              <li role='button' onClick={() => { handleEditLabelModal("rriteVlerenShtepiake") }}>{Translations(props?.langData, props?.selected_lang, 'rriteVlerenShtepiake')}</li>
              <li role='button' onClick={() => { handleEditLabelModal("rritjaSigurinShpise") }}>{Translations(props?.langData, props?.selected_lang, 'rritjaSigurinShpise')}</li>
              <li role='button' onClick={() => { handleEditLabelModal("reduktoPluhurinDheAlergjite") }}>{Translations(props?.langData, props?.selected_lang, 'reduktoPluhurinDheAlergjite')}</li>
              <li role='button' onClick={() => { handleEditLabelModal("RritKomoditetinNeShtepi") }}>{Translations(props?.langData, props?.selected_lang, 'RritKomoditetinNeShtepi')}</li>
              <li role='button' onClick={() => { handleEditLabelModal("ImprovedEnergyEfficiency") }}>{Translations(props?.langData, props?.selected_lang, 'ImprovedEnergyEfficiency')}</li>
              <li role='button' onClick={() => { handleEditLabelModal("reduktoZhurmen") }}>{Translations(props?.langData, props?.selected_lang, 'reduktoZhurmen')}</li>
              <li role='button' onClick={() => { handleEditLabelModal("zgjedhjeMeMirembajtjeTeUlet") }}>{Translations(props?.langData, props?.selected_lang, 'zgjedhjeMeMirembajtjeTeUlet')}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = state => {
  return {
    selected_lang: state.selected_lang,
    langData: state.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // setLangData: () => dispatch(setLangData()),
    // setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);