import React, { useEffect, useState } from 'react'
import AddProjectsModal from './modals/addProjectsModal';
import axios from '../../axios';
import { Link } from 'react-router-dom';
import "./projects.scss"
import ReactPaginate from 'react-paginate';
import Translations from '../../components/translations/translations';
import { setAuth, setLogIn, setSelectedLang } from '../../redux/actions';
import { connect } from 'react-redux';
import DeleteProjectModal from './modals/deleteProjectModal';

const Projects = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dataToModal, setDataToModal] = useState();
    const [projects, setProjects] = useState([])
    const [modalType, setModalType] = useState('')

    function getAllProjects() {
        axios.get("/api/admin/projects/all")
            .then(res => {
                // console.log(res)
                setProjects(res.data)
            })
            .catch((err) => {
                console.log('error fetching projects', err)
            })
    }

    function handleModalAdd() {
        setIsOpen(true)
        setModalType('add')
    }
    function handleModalEdit() {
        setIsOpen(true)
        setModalType('edit')
    }
    function handleDeleteModal(dataToModal) {
        setIsOpen(true)
        setModalType('delete')
        setDataToModal(dataToModal)
    }
    function handleModalClose() {
        setIsOpen(false)
    }




    const [shouldShowFilters, setShouldShowFilters] = useState("hideFilters")
    const [filteredData, setFilteredData] = useState("")
    const [filterOptions, setFilterOptions] = useState([])

    function getFiltersFromDB() {
        setFilterOptions([...new Set(projects.map((el) => el.business_type_id))])
    }
    // const map = new Map(Object.entries(filterOptions))
    function showFilter() {
        setShouldShowFilters('showFilters');
        if (shouldShowFilters == "showFilters") {
            setShouldShowFilters("hideFilters");
        }
    }
    function filterProjects(e) {
        setFilteredData(projects.filter((el) => el?.lloji_biznesit == e));
    }


    // -------------------- FILTERED PAGE DATA PAGINATION ---------------------\\
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
        window.scrollTo(0, 0);
    };

    const render = () => {
        const itemsPerPage = 10;
        const offset = currentPage * itemsPerPage;


        const filteredPageData = filteredData.slice(offset, offset + itemsPerPage);

        return (
            <>
                {filteredPageData?.map((el, index) => {
                    return (
                        <Link data-aos={index % 2 == 1 ? "fade-left" : "fade-right"} data-aos-duration="1000" className=" col-md-6 col-12 singleCard" to={`/project/${el?.page_unique_url}`}>
                            {/* <div className='' style={{ backgroundImage: `url(${process.env.REACT_APP_API}/${el?.bg_img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "415px" }} /> */}
                            <img src={`${process.env.REACT_APP_API}/${el?.bg_img}`} style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "415px" }} alt="" />
                            <div className="image_info">
                                {props?.selected_lang == "AL" ? <p className="cardTitle mb-5">{el?.obiject_name_al}</p>
                                    : props?.selected_lang == "EN" ? <p className="cardTitle mb-5">{el?.obiject_name_en}</p>
                                        : <p className="cardTitle mb-5">{el?.obiject_name_de}</p>
                                }
                                <div className="cardText mb-3 ">
                                    <i className="fas fa-map-marker-alt  " aria-hidden="true" /> &nbsp;
                                    {props?.selected_lang == "AL" ? <p className=" mb-5">{el?.location_al}</p>
                                        : props?.selected_lang == "EN" ? <p className=" mb-5">{el?.location_en}</p>
                                            : <p className=" mb-5">{el?.location_de}</p>
                                    }
                                </div>
                            </div>
                        </Link>
                    )
                })}
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
            </>);
    };
    // -------------------- FILTERED PAGE DATA PAGINATION ---------------------\\


    // -------------------- ALL PAGE DATA PAGINATION ---------------------\\
    const [allPageDataCurrentPage, setAllPageDataCurrentPage] = useState(0);

    const handleAllPageDataClick = (data) => {
        setAllPageDataCurrentPage(data.selected);
        window.scrollTo(0, 0);
    };

    const renderAllPageData = () => {
        const AllitemsPerPage = 10;
        const offset = allPageDataCurrentPage * AllitemsPerPage;

        const allPageData = projects?.slice(offset, offset + AllitemsPerPage);


        return (
            <>
                {allPageData?.map((el, index) => {
                    return (
                        <Link data-aos={index % 2 == 1 ? "fade-left" : "fade-right"} data-aos-duration="1000" className=" col-md-6 col-12 singleCard" to={`/project/${el?.page_unique_url}`}>
                            {/* <div className='' style={{ backgroundImage: `url(${process.env.REACT_APP_API}/${el?.bg_img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "415px" }} /> */}
                            <img src={`${process.env.REACT_APP_API}/${el?.bg_img}`} style={{ objectFit: 'cover', height: '415px', width: '100%' }} alt="" />

                            <div className="image_info">
                                {props?.selected_lang == "AL" ? <p className="cardTitle col-12">{el?.obiject_name_al}</p>
                                    : props?.selected_lang == "EN" ? <p className="cardTitle col-12">{el?.obiject_name_en}</p>
                                        : <p className="cardTitle col-12 ">{el?.obiject_name_de}</p>
                                }
                                <div className="cardText mb-3 d-flex d-flex justify-content-between ">
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-map-marker-alt  " aria-hidden="true" />
                                        {props?.selected_lang == "AL" ? <p className=" col-12">{el?.location_al}</p>
                                            : props?.selected_lang == "EN" ? <p className=" col-12">{el?.location_en}</p>
                                                : <p className="">{el?.location_de}</p>
                                        }
                                    </div>
                                    <div className='delete_container d-flex' onClick={() => handleDeleteModal(el)}>
                                        Fshij Projektin
                                        <i className=' delete_button fa fa-trash pr-2'></i>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                })}
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(projects?.length / AllitemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handleAllPageDataClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
            </>);
    };
    useEffect(() => {
        getAllProjects()
        getFiltersFromDB()
    }, [])
    return (
        <>
            {/* {console.log('arton po vyn kjo ', isOpen, modalType)} */}
            {isOpen === true &&
                modalType == "add" ?
                <AddProjectsModal isOpen={isOpen} handleModalClose={handleModalClose} getAllProjects={getAllProjects} />
                :
                modalType == "delete" ?
                    <DeleteProjectModal dataToModal={dataToModal} isOpen={isOpen} handleModalClose={handleModalClose} getAllProjects={getAllProjects} />
                    : null

            }
            <div className="container mb-4">
                <div className=" allProjects">
                    <div className="tekstiProjekteveTengjashme">
                        <div className="row">
                            <h1 className='titulliProjekteve col-6'>
                                {Translations(props?.langData, props?.selected_lang, 'projektetErealizuara')}
                            </h1>
                            <p className='numrimiProjekteve col-6'>{Translations(props?.langData, props?.selected_lang, 'ShowingLabel')}: {filteredData?.length !== 0 ? filteredData?.length : projects?.length} {Translations(props?.langData, props?.selected_lang, 'ProjekteLabel')}</p>

                            {/* <div className="row ">
                                <p className='filter col-6'>
                                     <button onClick={showFilter}>
                                        <i className="fas fa-light fa-sliders filter_icon" />  {Translations(props?.langData, props?.selected_lang, 'FiltroLabel')}
                                    </button> 
                                </p>
                            </div> */}
                            <div className={"container " + shouldShowFilters}>
                                <hr className='vijaNdarese col-12' />
                                <div className="d-flex flex-wrap">
                                    {shouldShowFilters == "showFilters" ? filterOptions.map((el) =>
                                        <button className=' filter_buttons' onClick={() => { filterProjects(el) }}>
                                            {Translations(props?.langData, props?.selected_lang, el)}
                                            {/* {el} */}
                                        </button>
                                    )
                                        :
                                        ""
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row mt-4">
                        {filteredData !== "" ?

                            render()

                            :
                            projects?.map((el, index) => {
                                return (
                                    <Link
                                        data-aos={index % 2 === 1 ? "fade-left" : "fade-right"}
                                        data-aos-duration="1000"
                                        className="col-md-6 col-12 singleCard"
                                        to={`/project/${el?.page_unique_url}`}
                                    >
                                        <img
                                            src={`${process.env.REACT_APP_API}/${el?.bg_img}`}
                                            style={{ objectFit: 'cover', height: '415px', width: '100%' }}
                                            alt=""
                                        />

                                        <div className="image_info">
                                            {props?.selected_lang == 'AL' ? (
                                                <p className="cardTitle col-12">{el?.obiject_name_al}</p>
                                            ) : props?.selected_lang == 'EN' ? (
                                                <p className="cardTitle col-12">{el?.obiject_name_en}</p>
                                            ) : (
                                                <p className="cardTitle col-12">{el?.obiject_name_de}</p>
                                            )}
                                            <div className="cardText mb-3 d-flex d-flex justify-content-between ">
                                                <div className="d-flex col align-items-center">
                                                    <i className="fas fa-map-marker-alt" aria-hidden="true" />
                                                    {props?.selected_lang == 'AL' ? (
                                                        <p className=" col-12 mx-2 m-auto ">{el?.location_al}</p>
                                                    ) : props?.selected_lang == 'EN' ? (
                                                        <p className=" col-12 mx-2 m-auto ">{el?.location_en}</p>
                                                    ) : (
                                                        <p className=" col-12 mx-2 m-auto ">{el?.location_de}</p>
                                                    )}
                                                </div>
                                                <div
                                                    className="delete_container d-flex"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDeleteModal(el);
                                                    }}
                                                >
                                                    Fshij Projektin
                                                    <i className="delete_button fa fa-trash pr-2" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                    <hr className='vijaNdarese col-12 mb-4 mt-2' />
                </div>

            </div>
            <div className="addButton" onClick={() => handleModalAdd()}>
                <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5 " style={{ color: "#0d6efd" }} ></i>
            </div>
        </>

    )
}

// export default Projects
const mapStateToProps = (state) => {
    return {
        logged_in: state.logged_in,
        auth: state.auth,
        selected_lang: state.selected_lang,
        langData: state.langData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLogIn: (data) => dispatch(setLogIn(data)),
        setAuth: (data) => dispatch(setAuth(data)),
        setSelectedLang: (data) => dispatch(setSelectedLang(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);