import { useEffect, React, useState } from 'react'
import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import './management.scss';
import aboutUsCompanyImage from "../../../assets/images/aboutUs/about_us_banner.png"
import insideCompanyImage from "../../../assets/images/aboutUs/inside_company_image.png"
import Translations from '../../../components/translations/translations';
import TranslationModal from '../../translatePagesModal/translationModal';
// import ourPartners from "../../DB/partneret"

const Management = (props) => {
    const [isOpen, setOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);

    const handleEditLabelModal = (key) => {
        setOpen(true);
        setDataToModal(key)
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    return (
        <>
            {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}

            <div className="about_us_layout">
                <img className="img-fluid about_us_company_image" src={aboutUsCompanyImage} />
                <div className="container">
                    <div className="about_us_content">
                        <p className='about_us_page_title mt-5'  onClick={() => { handleEditLabelModal("MenagjmentiLabel") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'MenagjmentiLabel')}` }} />
                        <p className='company_description' onClick={() => { handleEditLabelModal("Menagjmentidescription") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Menagjmentidescription')}` }} />

                        <img className='img-fluid about_us_inside_company_image' src={insideCompanyImage} alt="Inside company image" />
                    </div>
                    {/* <div className="mt-5 mb-5 partneretTane">
                    {ourPartners.map((el, index) => {
                        return (
                            <img  data-aos={index < 4 || (index >= 8 && index < 12)? "fade-right" : "fade-left"} data-aos-duration="1000" src={el?.img_src} alt={el.title} />
                        )
                    })}
                </div> */}
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Management);


