import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
} from '@mui/material';
import axios from '../../axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { setLangData } from '../../redux/actions';

const TranslationModal = (props) => {
    // console.log('TranslationModal props', props)
    const [labels, setLabels] = useState()

    useEffect(() => {
        axios.get(`/api/admin/labels/${props?.dataToModal}`)
            .then((res) => setLabels(res.data))
            .catch((err) => { console.log('error : ', err) })
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log('labels', name)
        setLabels({
            ...labels,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        axios.put('/api/admin/labels', labels)
            .then((response) => {
                // console.log('Project type added:', response.data);
                axios.get("/api/admin/labels/all")
                    .then((res) => {
                        props?.setLangData(res.data);
                        props.handleModalClose();
                    })
                    .catch(err => console.log('error getting labels', err))
            })
            .catch((error) => {
                toast.error("error : ", error)
                console.error('Error adding project type:', error);
            })
    };

    function autoAdjustTextareaHeight(e) {
        const textareas = document.querySelectorAll('.dynamic-textarea');
        let maxHeight = 0;
        textareas.forEach((textarea) => {
            textarea.style.height = 'auto';
            maxHeight = Math.max(maxHeight, textarea.scrollHeight);
        });
        textareas.forEach((textarea) => {
            textarea.style.height = `${maxHeight}px`;
        });
    }
    function setInitialTextareaHeight() {
        const textareas = document.querySelectorAll('.auto-adjust-textarea');
        textareas.forEach((textarea) => {
            textarea.style.height = 'auto'; // Reset the height to auto to calculate the new height
            textarea.style.height = textarea.scrollHeight + 10 + 'px'; // Set the height to match the content's scrollHeight
        });
    }

    useEffect(() => {
        setTimeout(() => {
            setInitialTextareaHeight()
        }, 500);
    }, [])
    return (

        <Dialog
            open={props?.isOpen}
            onClose={props?.handleModalClose}
            maxWidth="xl"
            className="custom-modal"

        >
            <DialogTitle>Edit modal</DialogTitle>
            <DialogContent  >
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <div className="row mb-3">
                        <div className="col-4">
                            <span className="text-left">Labela Shqip:</span>
                            <textarea
                                type="text"
                                name="content_al"
                                onInput={(e) => autoAdjustTextareaHeight(e)}
                                onChange={handleInputChange}
                                value={labels?.content_al}
                                className="form-control dynamic-textarea auto-adjust-textarea"
                                placeholder="Shqip"
                            />
                        </div>
                        <div className="col-4">
                            <span className="text-left">Labela Anglisht:</span>
                            <textarea
                                type="text"
                                name='content_en'
                                onInput={(e) => autoAdjustTextareaHeight(e)}
                                onChange={handleInputChange}
                                value={labels?.content_en}
                                className="form-control dynamic-textarea auto-adjust-textarea"
                                placeholder="Anglisht"
                            />
                        </div>
                        <div className="col-4">
                            <span className="text-left">Labela Gjermanisht:</span>
                            <textarea
                                type="text"
                                name='content_de'
                                onInput={(e) => autoAdjustTextareaHeight(e)}
                                onChange={handleInputChange}
                                value={labels?.content_de}
                                className="form-control dynamic-textarea auto-adjust-textarea"
                                placeholder="Gjermanisht"
                            />
                        </div>
                    </div>
                    <div className="button-container">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        selected_lang: state.selected_lang
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setLangData: (data) => dispatch(setLangData(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TranslationModal);