import React, { useRef, useState } from 'react';

import "./profile.scss"
import { connect } from 'react-redux';
import { setSelectedLang, setUserData } from '../redux/actions';

const ProfileModal = (props) => {
    const [newPicData, setNewPicData] = useState()
    const fileInputRef = useRef(null); // Create a ref for the file input
    console.log("handleModalClose", props)
    function openFileInput() {
        console.log('fileInputRef',)
        fileInputRef.current.click(); // Trigger the file input click event
    }
    function handleFileUpload(selectedFile) {
        console.log('selectedFile', selectedFile)
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const blobData = e.target.result;
                const imgElement = document.getElementById('selectedImage');
                imgElement.src = blobData;
            };

            reader.readAsDataURL(selectedFile);
        } else {
            console.log('No file selected');
        }
    }

    function handleImageUpload() {
        const formData = new FormData();
        formData.append('id', props?.user?.id);
        formData.append('pic_url', newPicData);
        // axios.put("/api/client/users/update", formData)
        //     .then(res => {
        //         // console.log('image sucess', res)
        //         toast.success("Imazhi u ngarkua me sukses!")
        //     })
        //     .catch(err => console.log('Error ocured while uploading image!'))
    }
    return (
        <dialog id="myModal" onClick={(e) => props?.setProfileOpen(e)} class="modal">
            <div class="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header mb-2">
                    <p>
                        Profili
                    </p>
                    <a className='closeButton' role='button' onClick={() => props?.setProfileOpen()}>
                        <i class="fa fa-window-close" aria-hidden="true">

                        </i>
                    </a>
                </div>
                <div className="user-info-container">
                    <div className="user-info row">
                        <div className="user-img-container col-xl-4 col-lg-12 col-md-12 col-12 text-center">
                            <div className="user-img " >
                                <div className="inner-img ">
                                    <div class="row">
                                        <div class=" medium-2 large-2 columns">
                                            <div class="circle mx-auto">
                                                {!props?.user_data?.pic_url ? (
                                                    !newPicData ? (
                                                        <img
                                                            className="profile-pic"
                                                            src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                                                        />
                                                    ) : (
                                                        <img id="selectedImage" src="" alt="Selected Image" />
                                                    )
                                                ) : (
                                                    <img
                                                        className="col-12"
                                                        id="selectedImage"
                                                        src={`${process.env.REACT_APP_API}${props?.user_data?.pic_url}`}
                                                    />
                                                )}

                                            </div>
                                            <div class="p-image">
                                                <i class="fa fa-camera upload-button" onClick={() => { document?.querySelector('.file-upload')?.click(); }}></i>
                                                <input class="file-upload" type="file" accept="image/*" onChange={(e) => { setNewPicData(e.target.files[0]); handleFileUpload(e.target.files[0]) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-info col-xl-5 col-lg-8 col-md-7 col-12">
                            <h2 className="tracking-in-expand">
                                {props?.user_data?.name} {props?.user_data?.surname}
                            </h2>

                            <div className="info-wrapper">
                                <div className="info-item">
                                    <h6>Emaili:</h6>
                                    <p>{props?.user_data?.email}</p>
                                </div>

                                <div className="info-item">
                                    <h6>Telefoni:</h6>
                                    <p>{props?.user_data?.phone_number}</p>
                                </div>

                                {/* <div className="info-item">
                      <h6>Address:</h6>
                      <p> 123 Main St, City, State</p>
                    </div> */}

                                <div className="info-item">
                                    <h6>Gjinia:</h6>
                                    <p>{props?.user_data?.gender === 1 ? "Femer" : "Mashkull"}</p>
                                </div>

                                {/* <div className="info-item">
                      <h6>Age:</h6>
                      <p>23</p>
                    </div> */}
                            </div>
                        </div>
                        <a role="button" onClick={() => handleImageUpload()} className="edit_profile_image" >Ruaj ndryshimet</a>
                    </div>
                </div>
            </div>
        </dialog>
    );
};

// export default ProfileModal;
// export default Projects
const mapStateToProps = (state) => {
    return {
        user_data: state.user_data,
        selected_lang: state.selected_lang,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: (data) => dispatch(setUserData(data)),
        setSelectedLang: (data) => dispatch(setSelectedLang(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal);