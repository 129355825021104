import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography
} from '@mui/material';
import axios from '../../../../axios';
import { toast } from 'react-toastify';

function AddProjectTypesModal(props) {
    const [projectType, setProjectType] = useState({
        type_al: props?.dataToModal?.type_al || '',
        type_en: props?.dataToModal?.type_en || '',
        type_de: props?.dataToModal?.type_de || '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProjectType({
            ...projectType,
            [name]: value,
        });
    };

    // console.log('arton props', props)
    const handleSubmit = () => {
        axios.post('/api/admin/project_types', projectType)
            .then((response) => {
                // console.log('Project type added:', response.data);
                props.handleTypesGet();
                props.handleModalClose();
            })
            .catch((error) => {
                toast.error("error : ", error)
                console.error('Error adding project type:', error);
            });
    };
    function handleDelete(sid) {
        axios.delete(`/api/admin/project_types/${sid}`,)
            .then((res) => {
                toast.success("Statusi u fshi me sukses");
                props?.handleTypesGet();
                props?.handleModalClose();
                // console.log('u fshi tipi', res)
            })
            .catch((err) => { console.log('err tu u fshi tipi', err) })
    }
    return (
        <>{props?.modalType == "add" ?
            <Dialog open={props.isOpen} onClose={props.handleModalClose} maxWidth="md">
                <DialogTitle>Add Project Type</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            label="Project Type (AL)"
                            name="type_al"
                            value={projectType.type_al}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                    <div>
                        <TextField
                            label="Project Type (EN)"
                            name="type_en"
                            value={projectType.type_en}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                    <div>
                        <TextField
                            label="Project Type (DE)"
                            name="type_de"
                            value={projectType.type_de}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.handleModalClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            :
            props?.modalType == "edit" ?
                <Dialog open={props.isOpen} onClose={props.handleModalClose} maxWidth="md">
                    <DialogTitle>Add Project Type</DialogTitle>
                    <DialogContent>
                        <div>
                            <TextField
                                label="Project Type (AL)"
                                name="type_al"
                                value={projectType.type_al}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div>
                            <TextField
                                label="Project Type (EN)"
                                name="type_en"
                                value={projectType.type_en}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div>
                            <TextField
                                label="Project Type (DE)"
                                name="type_de"
                                value={projectType.type_de}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={props.handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                :
                <Dialog open={props?.isOpen}>
                    <DialogTitle>Confirm Status Deletion</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            Are you sure you want to delete "{props?.dataToModal?.type_al}"?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props?.handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleDelete(props?.dataToModal?.id)} color="primary">
                            Yes, Delete
                        </Button>
                    </DialogActions>
                </Dialog>
        }
        </>
    );
}

export default AddProjectTypesModal;
