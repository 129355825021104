import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from '../../../../axios';

const AddCertificateModal = (props) => {
  // console.log('props modal partner', props)
  const [altText, setAltText] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleImagePost = () => {
    if (!image) {
      toast.error('Please select an image.');
      return;
    }

    const formData = new FormData();
    formData.append('certificate', image);
    formData.append('img_alt', altText);

    axios
      .post('/api/admin/certificates', formData)
      .then((res) => {
        props.handleGetCertificates();
        toast.success('Image uploaded successfully.');
        props.handleModalClose();
      })
      .catch((err) => {
        console.error('Error uploading image', err);
        toast.error('Error uploading image.');
      });
  };


  const handleDelete = () => {
    // Send a request to delete the partner here
    axios
      .delete(`/api/admin/certificates/${props?.dataToModal?.id}`)
      .then((res) => {
        // Handle successful deletion, e.g., update your partner list
        props.handleGetPartners();
        props.handleModalClose();
      })
      .catch((err) => {
        console.error('Error deleting partner', err);
        // Handle deletion error
      });
  };
  return (
    <>{props?.modalType == "add"
      ?
      <Dialog open={props.isOpen} onClose={props.handleModalClose}>
        <DialogTitle>Upload Certificate Image</DialogTitle>
        <DialogContent>
          {imagePreview && (
            <div>
              <img
                src={imagePreview}
                alt="Image Preview"
                style={{ maxWidth: '100%', maxHeight: '300px' }}
              />
            </div>
          )}
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </div>
          <div>
            <TextField
              label="Alt Text"
              type="text"
              value={altText}
              onChange={(e) => setAltText(e.target.value)}
              fullWidth
              margin="normal"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleModalClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleImagePost} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      :
      <Dialog open={props.isOpen} onClose={props.handleModalClose}>
        <DialogTitle>Delete Partner</DialogTitle>
        <DialogContent >
          <Typography variant="body1">
            Are you sure you want to delete this partner?
          </Typography>
          <img style={{ width: "132px" , textAlign:"center" }} src={`${process.env.REACT_APP_API}/${props?.dataToModal?.img_url}`} alt="" />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleModalClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={()=>handleDelete()} color="primary">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    }
    </>

  );
};

export default AddCertificateModal;
