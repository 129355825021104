import axios from '../../../axios';
import React, { useEffect, useState } from 'react';
import AddTypesModal from './modal/project_types_modal'; // Corrected import path
import { connect } from 'react-redux';
import editIcon from '../../../data/icons/edit.svg'
import deleteIcon from '../../../data/icons/delete.svg'

const ProjectTypes = (props) => {
    const [isOpen, setOpen] = useState(false); // State to control the modal
    const [modalType, setModalType] = useState('');
    const [dataToModal, setDataToModal] = useState(null);
    const [allTypes, setAllTypes] = useState([]);

    const handleModalAdd = () => {
        setOpen(true);
        setModalType('add');
    };
    function handleModalEdit(e) {
        setOpen(true)
        setDataToModal(e)
        setModalType('edit')
    }
    function handleModalDelete(e) {
        setOpen(true)
        setDataToModal(e)
        setModalType('delete')
    }
    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };

    function handleTypesGet() {
        axios
            .get('/api/admin/project_types/all')
            .then((res) => {
                setAllTypes(res.data);
                // console.log('i morr Typesat', res);
            })
            .catch((err) => {
                console.log('error me i marr Typesi', err);
            });
    }

    useEffect(() => {
        handleTypesGet();
    }, []);

    return (
        <>
            {isOpen && <AddTypesModal isOpen={isOpen} modalType={modalType} dataToModal={dataToModal} handleModalClose={handleModalClose} handleTypesGet={handleTypesGet} />}
            <h2 className='text-center mb-4 mt-4' >Ketu listohen te gjitha tipet e projekteve</h2>
            <table className="col-12 table table-hover">
                <thead>
                    <tr>
                        {/* <th scope="col">ID</th> */}
                        <th scope="col">Project Type AL</th>
                        <th scope="col">Project Type EN</th>
                        <th scope="col">Project Type DE</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {allTypes?.map((el) => {
                        return (
                            <tr key={el.id}>
                                {/* <th scope="row">{el.id}</th> */}
                                <td>{el?.type_al}</td>
                                <td>{el?.type_en}</td>
                                <td>{el?.type_de}</td>
                                <td className='col-2' onClick={() => handleModalEdit(el)} ><img role="button" src={editIcon} alt="" /></td>
                                <td className='col-2' onClick={() => handleModalDelete(el)}><img role="button" src={deleteIcon} alt="" /></td>

                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="addButton" onClick={handleModalAdd}>
                <i
                    type={'button'}
                    className="fa-solid fa-circle-plus fa-2xl pt-5"
                    style={{ color: '#0d6efd' }}
                ></i>
            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        logged_in: state.logged_in,
        auth: state.auth,
        selected_lang: state.selected_lang,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setLogIn: (data) => dispatch(setLogIn(data)),
        // setAuth: (data) => dispatch(setAuth(data)),
        // setSelectedLang: (data) => dispatch(setSelectedLang(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTypes);
