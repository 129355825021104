import React, { useEffect, useState } from 'react'
import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import './pergjegjsiaSociale.scss';
import aboutUsCompanyImage from "../../../assets/images/socialResponsibility/Pergjegjesia-sociale.png"
import insideCompanyImage2 from "../../../assets/images/socialResponsibility/Reth-nesh-2.jpg"
import insideCompanyImage1 from "../../../assets/images/socialResponsibility/Rreth-nesh-1.JPG"
// import ourPartners from "../../DB/partneret"
import kfShkendija from "../../../assets/images/socialResponsibility/KF-Shkendija---Hajvali.png"
// import pvcImage from "/M-technologie-PVC-Product-2.png"

import "./pergjegjsiaSociale.scss"
import Translations from '../../../components/translations/translations';
import TranslationModal from '../../translatePagesModal/translationModal';


const PergjegjsiaSociale = (props) => {

    const [isOpen, setOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);

    const handleEditLabelModal = (key) => {
        setOpen(true);
        setDataToModal(key)
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    return (
        <>
            {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}
            <div className="PergjegjsiaSociale_layout">
                <img className="img-fluid about_us_company_image" src={aboutUsCompanyImage} />
                <div className="container">
                    <div className="about_us_content">
                        <p className='about_us_page_title mt-5' onClick={() => { handleEditLabelModal("pergjegjsiaSocialeLabel") }}>{Translations(props?.langData, props?.selected_lang, 'pergjegjsiaSocialeLabel')}</p>
                        <p className='company_description mb-5' onClick={() => { handleEditLabelModal("PergjegjsiaSocialeTekstiLabel") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'PergjegjsiaSocialeTekstiLabel')}` }}></p>
                        <ul>
                            <li onClick={() => { handleEditLabelModal("Pika-1-pergjegjsiaSociale") }}>{Translations(props?.langData, props?.selected_lang, 'Pika-1-pergjegjsiaSociale')}</li>
                            <li onClick={() => { handleEditLabelModal("Pika-2-pergjegjsiaSociale") }}>{Translations(props?.langData, props?.selected_lang, 'Pika-2-pergjegjsiaSociale')}</li>
                            <li onClick={() => { handleEditLabelModal("Pika-3-pergjegjsiaSociale") }}>{Translations(props?.langData, props?.selected_lang, 'Pika-3-pergjegjsiaSociale')}</li>
                            <li onClick={() => { handleEditLabelModal("Pika-4-pergjegjsiaSociale") }}>{Translations(props?.langData, props?.selected_lang, 'Pika-4-pergjegjsiaSociale')}</li>
                        </ul>
                        <p className='company_description mt-5 mb-5' onClick={() => { handleEditLabelModal("Pika-5-pergjegjsiaSociale") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Pika-5-pergjegjsiaSociale')}` }}></p>
                    </div>
                    <div className="sponzorizime">
                        <div className="row mt-4 mb-2" style={{ justifyContent: "space-around" }}>
                            <img className="col-sm-3 col-md-4 col-12 " src={kfShkendija} />
                            <div className="col-md-7 col-12 single_product_layout">
                                <h1 className='SocialResponsibility_title' onClick={() => { handleEditLabelModal("M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-label") }}>{Translations(props?.langData, props?.selected_lang, 'M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-label')}</h1>
                                <p className='SocialResponsibility_description_main_page' onClick={() => { handleEditLabelModal("M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-description") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-description')}` }}>

                                </p>
                                <a className='single_product_button' onClick={() => { handleEditLabelModal("vizitoFaqen-Label") }}>{Translations(props?.langData, props?.selected_lang, 'vizitoFaqen-Label')}</a>
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-between mt-4 mb-2">
                        <div className="col-md-7 col-12 single_product_layout">
                            <h1 className='single_product_title'>{Translations(props?.langData, props?.selected_lang, 'M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-label')}</h1>
                            <p className='single_product_description_main_page' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-description')}` }}>

                            </p>
                            <a href="https://www.facebook.com/KFShkendijaHajvali/" target='_blank' className='single_product_button'>{Translations(props?.langData, props?.selected_lang, 'vizitoFaqen-Label')}</a>
                        </div>
                        <img className="col-sm-3 col-md-4 col-12 " src={kfShkendija} />
                    </div> */}
                    </div>
                    {/* <div className="mt-5 mb-5 partneretTane">
                    {ourPartners.map((el, index) => {
                        return (
                            <img data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={el?.img_src} alt={el.title} />
                        )
                    })}
                </div> */}
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PergjegjsiaSociale);