import React, { useEffect, useState } from 'react'
import "./ourProducts.scss"
import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Translations from '../../../components/translations/translations';
import TranslationModal from '../../translatePagesModal/translationModal';
// import ourPartners from "../../DB/partneret"

import pvcImage from "../../../assets/images/ourProducts/M-technologie-PVC-Product-2.png"
import aluminImage from "../../../assets/images/ourProducts/Produktet-e-Aluminit.jpg"
import glassImage from "../../../assets/images/ourProducts/Spider-Glass.png"
import productsBannes from "../../../assets/images/ourProducts/Benneri_Cka-ofrojme.jpg"
const OurProducts = (props) => {

    const [isOpen, setOpen] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);

    const handleEditLabelModal = (key) => {
        setOpen(true);
        setDataToModal(key)
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataToModal(null)
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])


    return (
        <>
            {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}

            <div className="our_prducts_layout">
                <div className='opening_parent_slider'>
                    <div className="banner_layout">
                        <img src={productsBannes} className="single_product_image_banner img-fluid" alt="..." />
                        <div className="container banner_description">
                            <p className='col' onClick={() => { handleEditLabelModal("PershkrimiBaneritTekFaqjaProdukteve") }}>{Translations(props?.langData, props?.selected_lang, 'PershkrimiBaneritTekFaqjaProdukteve')}</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="d-flex  flex-wrap justify-content-between mt-5 mb-5">
                        <div className=" col-md-7 col-12 single_product_layout">
                            <h1 className='single_product_title' onClick={() => { handleEditLabelModal("ProduktetNgaPVC") }}>{Translations(props?.langData, props?.selected_lang, 'ProduktetNgaPVC')}</h1>
                            <p className='single_product_description_main_page' onClick={() => { handleEditLabelModal("M-Technologie-ofron-dyer-dhe-dritare-nga-PVC,-përmes-teknologjisë-dhe-paisjeve-Label") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'M-Technologie-ofron-dyer-dhe-dritare-nga-PVC,-përmes-teknologjisë-dhe-paisjeve-Label')}` }}>
                            </p>
                            <Link className='single_product_button' onClick={() => { handleEditLabelModal("Më-shumë-LabellProducts") }}>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                        </div>
                        <img className="col-sm-3 col-md-4 col-12 img-fluid" src={pvcImage} />
                    </div>
                    <div className="d-flex flex-wrap  justify-content-between mt-4 mb-2">
                        <img className="col-sm-3 col-md-4 col-12 " src={aluminImage} />
                        <div className="col-md-7 col-12 single_product_layout">
                            <h1 className='single_product_title' onClick={() => { handleEditLabelModal("ProduktetNgaAlumini") }}>{Translations(props?.langData, props?.selected_lang, 'ProduktetNgaAlumini')}</h1>
                            <p className='single_product_description_main_page' onClick={() => { handleEditLabelModal("Profilet-vertikale-dhe-horizontale-të-këtij-sistemi-të-fasadës-kombinohen-lehtë-me--Label") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Profilet-vertikale-dhe-horizontale-të-këtij-sistemi-të-fasadës-kombinohen-lehtë-me--Label')}` }}>

                            </p>
                            <Link className='single_product_button' onClick={() => { handleEditLabelModal("Më-shumë-LabellProducts") }}>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap  justify-content-between mt-4 mb-5">
                        <div className="col-md-7 col-12 single_product_layout">
                            <h1 className='single_product_title' onClick={() => { handleEditLabelModal("ProduktetExhamitTitle") }}>{Translations(props?.langData, props?.selected_lang, 'ProduktetExhamitTitle')}</h1>
                            <p className='single_product_description_main_page' onClick={() => { handleEditLabelModal("ProduktetExhamit-Teksti-tek-produktet-tona-Label") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ProduktetExhamit-Teksti-tek-produktet-tona-Label')}` }}>
                            </p>
                            <Link className='single_product_button' onClick={() => { handleEditLabelModal("Më-shumë-LabellProducts") }}>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                        </div>
                        <img className="col-sm-3 col-md-4 col-12 img-fluid" src={glassImage} />
                    </div>
                </div>
                {/* <div className="container mt-2 mb-5 partneretTane">
                {ourPartners.map((el, index) => {
                    return (
                        <img data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={`../.${el?.img_src}`} alt={el.title} />
                    )
                })}
            </div> */}
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.selected_lang,
        langData: state.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: () => dispatch(setLangData()),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurProducts);