import { useState } from 'react';
import './changePassMsg.scss';
import axios from '../../../axios';
import companyImage from '../../../data/images/page-images/about_us_banner.webp'
import { toast } from 'react-toastify';


import { connect } from 'react-redux'
import { setLogIn, setAuth } from '../../../redux/actions';
import { Link } from 'react-router-dom';

const ChangePassMsg = (props) => {
    // console.log('logjin props', props)



    return (
        <div className="container-fluid h-100">
            <div className="row h-100 min-vh-100">
                <div className="col-md-12 d-md-block p-0 background-color">
                    <img className="w-100 h-100 object-fit-cover" src={companyImage} alt="Company image" />
                </div>
                <div className="col-md-12 d-flex flex-column justify-content-center min-vh-100 login-wrapper">
                    <form className="ms-auto  col-10 me-auto rounded-lg Requested-Password-Change-form p-md-5 p-2 rounded">
                        <h2 className="text-4xl text-white font-weight-bold text-center col">Requested Password Change Sucessfully</h2>
                        <div className="Requested-Password-Change-form-group text-white py-2">
                            <p htmlFor="username" className='col text-center'>An email was send to notify you for the next steps to changing your password. Please follow the instructions and procede with cauthin and dont share the sent email with anyone! </p>
                        </div>
                            <Link to="/" className="mx-auto col-3 text-center btn btn-primary btn-block mt-3">Go back to Login</Link>

                    </form>
                </div>
            </div>
        </div>
    );
};

// export default Login;

const mapStateToProps = (state) => {
    return {
        logged_in: state.logged_in,
        counter: state.counter
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setLogIn: (data) => dispatch(setLogIn(data)),
        setAuth: (data) => dispatch(setAuth(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassMsg);