import axios from '../../../axios'
import React, { useEffect, useState } from 'react'
import AddStatusModal from './statusModal/statusModal';
import { connect } from 'react-redux';


const ProjectStatus = (props) => {
    const [isOpen, setOpen] = useState(false); // State to control the modal
    const [modalType, setModalType] = useState("");
    const [allStatuses, setAllStatuses] = useState([]);
    const [dataToModal, setDataToModal] = useState([]);

    const handleModalAdd = () => {
        setOpen(true);
        setModalType("add")
    };

    const handleModalClose = () => {
        setDataToModal(null);
        setOpen(false);
    };
    function handleModalEdit(e) {
        setOpen(true)
        setDataToModal(e)
        setModalType('edit')
    }
    function handleModalDelete(e) {
        setOpen(true)
        setDataToModal(e)
        setModalType('delete')
    }


    function handleStatusGet() {
        axios.get("/api/admin/project_status/all")
            .then((res) => {
                setAllStatuses(res.data)
                // console.log('u postu materiali', res)
            })
            .catch((err) => { console.log('nuku postu materiali', err) })
    }
    useEffect(() => {
        handleStatusGet()
    }, [])

    return (
        <>
            {isOpen === true &&
                <AddStatusModal
                    isOpen={isOpen}
                    handleStatusGet={handleStatusGet}
                    modalType={modalType}
                    handleModalClose={handleModalClose}
                    dataToModal={dataToModal}
                />
            }
            <h2 className='text-center mb-4 mt-4' >Ketu listohen te gjitha statuset e projekteve</h2>
            <table className=" col-12 table table table-hover">
                <thead>
                    <tr>
                        {/* <th scope="col">ID</th> */}
                        <th scope="col">Status AL</th>
                        <th scope="col">Status EN</th>
                        <th scope="col">Status DE</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {allStatuses?.map((el, index) => {
                        return (
                            <tr key={index}>
                                {/* <th scope="row">{el.id}</th> */}
                                <td>{el?.status_al}</td>
                                <td>{el?.status_en}</td>
                                <td>{el?.status_de}</td>
                                <td onClick={() => { handleModalEdit(el) }}><i className='fa fa-edit '></i></td>
                                <td onClick={() => { handleModalDelete(el) }}><i className='mr-3 fa fa-trash'></i></td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
            <div className="addButton" onClick={handleModalAdd}>
                <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5 " style={{ color: "#0d6efd" }} ></i>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        logged_in: state.logged_in,
        auth: state.auth,
        selected_lang: state.selected_lang
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setLogIn: (data) => dispatch(setLogIn(data)),
        // setAuth: (data) => dispatch(setAuth(data)),
        // setSelectedLang: (data) => dispatch(setSelectedLang(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStatus);