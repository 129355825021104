import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@mui/material';
import axios from '../../../axios';
import { toast } from 'react-toastify';

function DeleteProjectModal({ isOpen, dataToModal, handleModalClose, getAllProjects }) {
    const handleDelete = (pid) => {
        axios
            .delete(`/api/admin/projects/delete/${pid}`)
            .then((res) => {
                // console.log(res);
                getAllProjects();
                handleModalClose();
                getAllProjects();
                toast.success('Projekti u fshi me sukses!');
            })
            .catch((err) => {
                toast.error(`Error: ${err}`);
                handleModalClose();
                console.error('Error:', err);
            });
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={handleModalClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete "{dataToModal.obiject_name_al}"?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDelete(dataToModal?.id)} color="primary">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteProjectModal;
