import axios from '../../axios'
import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material'; // Import the necessary components from @mui/material
import AddMaterialsModal from './materialModal/modal';
import { setAuth, setLogIn, setSelectedLang } from '../../redux/actions';
import { connect } from 'react-redux';
import editIcon from '../../data/icons/edit.svg'
import deleteIcon from '../../data/icons/delete.svg'

const Materials = (props) => {
    const [isOpen, setOpen] = useState(false); // State to control the modal
    const [modalType, setModalType] = useState("");
    const [allMaterials, setAllMaterials] = useState([]);
    const [dataToModal, setDataToModal] = useState()

    const handleModalAdd = () => {
        setOpen(true);
        setModalType("add")
    };

    const handleModalClose = () => {
        setDataToModal(null);
        setOpen(false);
    };
    function handleModalEdit(e) {
        setOpen(true)
        setDataToModal(e)
        setModalType('edit')
    }
    function handleModalDelete(e) {
        setOpen(true)
        setDataToModal(e)
        setModalType('delete')
    }

    function handleMaterialGet() {
        axios.get("/api/admin/project_materials/all")
            .then((res) => {
                setAllMaterials(res.data)
                // console.log('u postu materiali', res)
            })
            .catch((err) => { console.log('u postu materiali', err) })
    }
    useEffect(() => {
        handleMaterialGet()
    }, [])

    return (
        <>
            {isOpen === true &&
                <AddMaterialsModal
                    handleMaterialGet={handleMaterialGet}
                    dataToModal={dataToModal}
                    setDataToModal={() => setDataToModal(null)}
                    modalType={modalType}
                    isOpen={isOpen}
                    handleModalClose={handleModalClose}
                />
            }
            {/* {
                console.log('propsa vyn', props)
            } */}

            <h2 className='text-center mb-3' >Ketu listohen te gjitha materialet</h2>
            <table className=" col-12 table table table-hover">
                <thead>
                    <tr>
                        <th className="col-3">Shqip</th>
                        <th className="col-3">English</th>
                        <th className="col-3">German</th>
                        <th className="col-2">Edit</th>
                        <th className="col-2">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {allMaterials?.map((el, index) => {
                        return (
                          
                                <tr key={index}> 
                                    <td className='col-3'>{el?.material_al}</td>
                                    <td className='col-3'>{el?.material_en}</td>
                                    <td className='col-3'>{el?.material_de}</td>
                                    <td className='col-2' onClick={() => handleModalEdit(el)} ><img role="button" src={editIcon} alt="" /></td>
                                    <td className='col-2' onClick={() => handleModalDelete(el)}><img role="button" src={deleteIcon} alt="" /></td>

                                    {/* <td className='col-2'><i className='fas fa-trash' /></td> */}
                                </tr>

                           
                        )
                    })}

                </tbody>
            </table>

            <div className="addButton" onClick={handleModalAdd}>
                <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5 " style={{ color: "#0d6efd" }} ></i>
            </div>
        </>
    )
}

// export default Materials
const mapStateToProps = (state) => {
    return {
        logged_in: state.logged_in,
        auth: state.auth,
        selected_lang: state.selected_lang
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLogIn: (data) => dispatch(setLogIn(data)),
        setAuth: (data) => dispatch(setAuth(data)),
        setSelectedLang: (data) => dispatch(setSelectedLang(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Materials);