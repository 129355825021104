import React, { useState } from 'react'
import './contactUs.scss'

import { setLangData, setSelectedLang } from "../../../redux/actions";
import { connect } from 'react-redux';
import Translations from '../../../components/translations/translations';
import TranslationModal from '../../translatePagesModal/translationModal';


const ContactUs = (props) => {
  const [isOpen, setOpen] = useState(null);
  const [dataToModal, setDataToModal] = useState(null);

  const handleEditLabelModal = (key) => {
    setOpen(true);
    setDataToModal(key)
  };

  const handleModalClose = () => {
    setOpen(false);
    setDataToModal(null)
  };
  return (
    <>
      {isOpen && <TranslationModal isOpen={isOpen} dataToModal={dataToModal} handleModalClose={handleModalClose} />}
      <div className="contact_layout">
        <div className="container">
          <div className="row data">
            <div className="harta col-md-7">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2930.2013991046765!2d21.1389088!3d42.741791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1354a055b3904d19%3A0x6b8cdacb1fdfd54f!2sM-Technologie!5e0!3m2!1sen!2s!4v1676252285957!5m2!1sen!2s" loading="eger" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="informatat col-md-5">
              <p className="contact_title" role='button' onClick={() => { handleEditLabelModal("Na-Kontaktoni-LABEL") }}>{Translations(props?.langData, props?.selected_lang, 'Na-Kontaktoni-LABEL')}</p>
              <div className="kontakt_info">
                <div className='d-flex'>
                  <i className='fa fa-phone ' /><p role='button' onClick={() => { handleEditLabelModal("Telefoni-LABEL") }}>{Translations(props?.langData, props?.selected_lang, 'Telefoni-LABEL')}</p> &nbsp;
                  <a
                    //  href={`tel:${Translations(props?.langData, props?.selected_lang, 'NumriPareKontaktuesTelefonues')}`} 
                    type='tel'>
                    <strong onClick={() => { handleEditLabelModal("NumriPareKontaktues") }}>{Translations(props?.langData, props?.selected_lang, 'NumriPareKontaktues')} </strong>

                  </a>
                  <p onClick={() => { handleEditLabelModal("NumriPareKontaktuesTelefonues") }}> &nbsp;  {Translations(props?.langData, props?.selected_lang, 'NumriPareKontaktuesTelefonues')}</p>
                </div>
                <div className='d-flex'>
                  <i className='fa fa-phone' />
                  <p role='button' onClick={() => { handleEditLabelModal("Telefoni-LABEL") }}>{Translations(props?.langData, props?.selected_lang, 'Telefoni-LABEL')}</p>
                  &nbsp;
                  <a
                    onClick={() => { handleEditLabelModal("NumriDytKontaktues") }}
                    // href={`tel:${Translations(props?.langData, props?.selected_lang, 'NumriDytKontaktuesTelefonues')}`}
                    type='tel'>
                    <strong>{Translations(props?.langData, props?.selected_lang, 'numriDytKontaktues')}</strong>
                  </a>
                  <p onClick={() => { handleEditLabelModal("NumriDytKontaktuesTelefonues") }}> &nbsp;  {Translations(props?.langData, props?.selected_lang, 'NumriPareKontaktuesTelefonues')}</p>

                </div>
                <div >
                  <i className='fa fa-inbox' />
                  <a href="mailto:shitja@m-technologie.com" type='email'>
                    shitja@m-technologie.com
                  </a>
                </div>
                <div className='mt-2'>
                  <i className='fa fa-inbox' />
                  <a href="mailto:info@m-technologie.com" type='email'>
                    info@m-technologie.com
                  </a>
                </div>
              </div>
              <p className='welcoming_pharagraph ' role='button' onClick={() => { handleEditLabelModal("Contact-Us-Page-Text-Label") }} dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Contact-Us-Page-Text-Label')}` }}></p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    selected_lang: state.selected_lang,
    langData: state.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLangData: (labels) => dispatch(setLangData(labels)),
    setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);


// export default ContactUs;